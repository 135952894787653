import * as SnackbarEventAction from '../../actions/snackbarEvent';
import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactNode } from 'react';
import { WikusButton } from './button';
import { useActions } from '../../actions';

export type WikusConfirmationProps = Omit<DialogProps, 'buttons' | 'onAbort' | 'classes'> & {
  title: ReactNode;
  children: ReactNode;
  onConfirm?: () => void;
  message?: string;
  onAbort?: () => void;
}

const WikusConfirmation = ({ children, title, onConfirm, onAbort, message, ...props }: WikusConfirmationProps) => {
  const snackbarActions: typeof SnackbarEventAction = useActions(
    SnackbarEventAction
  );
  const intl = useIntl();

  const onConfirmInternal: typeof onConfirm = async () => {
    const result = await onConfirm?.();
    if (message && typeof result === 'boolean') {
      if (result) {
        snackbarActions.addSnackbarEvent({
          message: intl.formatMessage({
            id:`success.${message}`,
          }),
          severity: 'success',
        });
        props.onClose?.({}, 'backdropClick');
      } else {
        snackbarActions.addSnackbarEvent({
          message: intl.formatMessage({
            id: `errors.${message}`,
          }),
          severity: 'error',
        });
      }
    }

    return result;
  }
  
  return (
    <Dialog
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      {...props}
    >
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent dividers>
        {children}
      </DialogContent>
      <DialogActions>
        <WikusButton autoFocus onClick={onAbort} color="primary" variant="outlined">
          <FormattedMessage id="confirmation.abort" />
        </WikusButton>
        <WikusButton onClick={onConfirmInternal} color="primary">
          <FormattedMessage id="confirmation.confirm" />
        </WikusButton>
      </DialogActions>
    </Dialog>
  );
}
// set for storybook to render html preview correctly
WikusConfirmation.displayName = 'WikusConfirmation';

export {
  WikusConfirmation
};