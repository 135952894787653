import {
  CreateCustomerUser,
  CustomerUserAction,
  CustomerUserActions,
  EditCustomerUser
} from './../model';

export function fetchCustomerUsers(customerId: string): CustomerUserAction {
  return {
    type: CustomerUserActions.FETCH_CUSTOMER_USERS,
    payload: {
      request: {
        url: `/users/forcustomer/${customerId}`,
        method: 'GET',
      },
    },
  };
}

export function createCustomerUser(
  user: CreateCustomerUser,
  customerId: string
): CustomerUserAction {
  return {
    type: CustomerUserActions.CREATE_CUSTOMER_USER,
    payload: {
      request: {
        url: `/users/forcustomer/${customerId}`,
        method: 'POST',
        data: user,
      },
    },
  };
}

export function updateCustomerUser(user: EditCustomerUser, customerUserId: number): CustomerUserAction {
  return {
    type: CustomerUserActions.UPDATE_CUSTOMER_USER,
    payload: {
      request: {
        url: `/users/${customerUserId}`,
        method: 'PUT',
        data: user,
      },
    },
  }
}

export function deleteCustomerUser(id: string | number): CustomerUserAction {
  return {
    type: CustomerUserActions.DELETE_CUSTOMER_USER,
    payload: {
      request: {
        url: `/users/${id}`,
        method: 'DELETE',
      },
    },
  }
}
