import { findKey } from 'lodash';
import countries from 'i18n-iso-countries';
import countriesDe from 'i18n-iso-countries/langs/de.json';
import languages from '@cospired/i18n-iso-languages';
import languagesDe from '@cospired/i18n-iso-languages/langs/de.json';

countries.registerLocale(countriesDe);
languages.registerLocale(languagesDe);

export const DefaultLanguage = 'de';

export function convertCustomLanguage(language?: string): string {
  const mapping = {
    'de': [/d2/i, /kd/i, /fd/i],
    'en': [/gb/i, /g2/i, /ke/i, /fe/i],
    'es': [/fs/i],
  };
  const customLanguage = language && findKey(mapping, (val) => val.some(r => r.test(language)));
  return customLanguage || language?.toLowerCase() || DefaultLanguage;
}

export const SupportedOfferLanguages = ['en', 'de'];

export {
  countries,
  languages
};