import { ThemeOptions } from '@material-ui/core';

const ColorOptions: ThemeOptions = {
  palette: {
    text: {
      primary: '#1D1F29',
    },
    primary: {
      main: '#1C2C77',
      contrastText: '#fff',
    },
    secondary: {
      main: '#D8232A',
      dark: '#BF000D',
      contrastText: '#fff',
    },
    grey: {
      200: '#F7F7F8',
      300: '#EDEFF5',
      400: '#E4E5EB',
      500: '#797A80',
    },
    background: {
      default: '#EDEFF5',
      paper: '#fff',
    },
    success: {
      light: '#CDFFD2',
      main: '#50D330',
    },
    warning: {
      light: '#FFD568',
      main: '#FDAF3A',
    },
    error: {
      light: '#FFC5C5',
      main: '#FC7D7D',
    },
  },
};

export default ColorOptions;