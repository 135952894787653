import { Box, CardActionArea, CardContent, CardProps, Grid, Theme } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles';
import { PaperClassKey } from '@material-ui/core';
import { ReactNode } from 'react';
import { TeaserAction } from './icons';
import { WikusTag } from './tag';
import { WikusTypography } from './typography';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

const styles = (theme: Theme) => ({
  root: {
    padding: `${theme.spacing(5)}px ${theme.spacing(4)}px`,
    borderRadius: 0,
  },
  interactiveRoot: {
    transition: 'box-shadow 0.2s ease-in-out',
    '&:hover': {
      boxShadow: theme.shadows[2],
      '& svg': {
        color: theme.palette.primary.main,
      },
    },
  },
  cardRoot: {
    padding: `${theme.spacing(5)}px ${theme.spacing(4)}px ${theme.spacing(4)}px`,
  },
  boxCardRoot: {
    width: '400px',
  },
  smallCardRoot: {
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
  },
  svgRoot: {
    color: theme.palette.primary.light,
  },
  contentRoot: {
    marginBottom: `${theme.spacing(4)}px`,
  },
  titleLine: {
    top: '10px',
    left: 0,
    margin: 0,
    width: '100%',
  },
  titleText: {
    display: 'inline-block',
    background: theme.palette.background.paper,
    paddingRight: '7.5px',
  },
});

export type WikusPanelSize = 'small' | 'large' | undefined;
export type WikusPanelStatus = 'warning' | 'success' | undefined;

export interface WikusPanelClasses extends Partial<ClassNameMap<PaperClassKey>> {
  smallCardRoot?: string;
  contentRoot?: string;
  cardRoot?: string;
  interactiveRoot?: string;
  boxCardRoot?: string;
  svgRoot?: string;
  titleLine?: string;
  titleText?: string;
};

export interface WikusPanelProps extends CardProps {
  size?: WikusPanelSize;
  status?: WikusPanelStatus;
  classes?: WikusPanelClasses;
  boxPanel?: boolean;
  description?: string;
  notification?: ReactNode;
};

const WikusPanel = withStyles(styles)(({ classes, title, description, size, boxPanel, children, ...props }: WikusPanelProps) => {
  let rootClass = classes?.root;
  if (size === 'small') {
    rootClass += ` ${classes?.smallCardRoot}`;
  }
  if (boxPanel) {
    rootClass += ` ${classes?.boxCardRoot}`;
  }

  const hasChildren = Array.isArray(children)
    ? children?.length
    : children;

  return (
    <Card
      classes={{
        root: rootClass,
      }}
      {...props}>
      <Box pb={((title || description) && hasChildren) ? (size === 'small' ? 2 : 4) : 0}>
        {title && (
          <div style={{ position: 'relative' }}>
            <hr style={{ position: 'absolute' }} className={classes?.titleLine}></hr>
            <WikusTypography style={{ position: 'relative' }} classes={{
              root: classes?.titleText,
            }} isBold={true}>{title}</WikusTypography>
          </div>
        )}
        {description && (
          <WikusTypography variant="caption">{description}</WikusTypography>
        )}
      </Box>
      <CardContent style={{ padding: 0 }}>
        {children}
      </CardContent>
    </Card >
  );
});
WikusPanel.displayName = 'WikusPanel';

const WikusInteractivePanel = withStyles(styles)(({ classes, ...props }: WikusPanelProps) => (
  <CardActionArea>
    <WikusPanel
      {...props}
      classes={{
        root: `${classes?.root} ${classes?.interactiveRoot}`,
      }}>

    </WikusPanel>
  </CardActionArea>
));
WikusInteractivePanel.displayName = 'WikusInteractivePanel';

const WikusCard = withStyles(styles)(({ classes, title, children, notification, ...props }: WikusPanelProps) => (
  <WikusInteractivePanel
    {...props}
    classes={{
      root: `${classes?.root} ${classes?.cardRoot}`,
    }}>
    <Grid container justifyContent="space-between" wrap="nowrap">
      <WikusTypography style={{ whiteSpace: 'pre-line' }} gutterBottom color="primary" variant="h1">{title}</WikusTypography>
      {!!notification && <WikusTag label={notification} color="secondary" />}
    </Grid>
    <WikusTypography classes={{
      root: classes?.contentRoot,
    }}>{children}</WikusTypography>
    <TeaserAction classes={{
      root: classes?.svgRoot,
    }}></TeaserAction>
  </WikusInteractivePanel>
));
WikusCard.displayName = 'WikusCard';

export {
  WikusPanel,
  WikusInteractivePanel,
  WikusCard
};