import {
  CustomerStatisticsAction,
  CustomerStatisticsActions
} from './../model';

export const fetchStatisticsStock = ({
  customerId,
  articleIds,
  year,
  month,
  unitCount = 6,
}: {
  customerId: string;
  articleIds?: number[];
  year?: number;
  month?: number;
  unitCount?: number;
}): CustomerStatisticsAction => ({
  type: CustomerStatisticsActions.FETCH_STATISTICS_STOCK,
  payload: {
    request: {
      url: `/stock-articles/forcustomer/${customerId}/statistics`,
      method: 'GET',
      params: {
        articleIds: (articleIds?.length ?? 0 > 0) ? JSON.stringify(articleIds) : 'all',
        unitCount: (!year && !month) ? unitCount : undefined,
        unit: (!year && !month) && (unitCount > 1) ? 'month' : 'day',
        year,
        month,
      },
    },
  },
});

export const fetchStatisticsOrders = ({
  customerId,
  articleIds,
}: {
  customerId: string;
  articleIds?: number[];
}): CustomerStatisticsAction => ({
  type: CustomerStatisticsActions.FETCH_STATISTICS_ORDERS,
  payload: {
    request: {
      url: `/stock-articles/forcustomer/${customerId}/orders`,
      method: 'GET',
      params: {
        articleIds,
      },
    },
  },
});

export const fulfillStatisticsOrder = ({
  customerId,
  orderId,
}: {
  customerId: string;
  orderId: number;
}): CustomerStatisticsAction => ({
  type: CustomerStatisticsActions.FULFILL_STATISTICS_ORDER,
  payload: {
    request: {
      url: `/stock-articles/forcustomer/${customerId}/orders/${orderId}/fulfill`,
      method: 'PUT',
    },
  },
});