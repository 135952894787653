import { FormattedMessage } from 'react-intl';
import { Grid } from '@material-ui/core';
import { NavigationUrl, WikusHero } from '../components/core/hero';
import { WikusSection } from '../components/core/spacing';
import { WikusTypography } from '../components/core/typography';
import { withStyles } from '@material-ui/core/styles';
import useWindowDimensions from '../hooks/useWindowDimensions';

import { Routes } from './../../../routes';

const styles: any = (theme: any) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: 1,
      position: 'relative',
      maxWidth: '1024px',
      width: '100%',
      margin: 'auto',
    },
    contentCentered: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    footer: {
      padding: `${theme.spacing(6)}px ${theme.spacing(5)}px ${theme.spacing(
        5
      )}px`,
    },
    footerLine: {
      borderTop: `1px solid ${theme.palette?.grey?.[400]}`,
      width: '100%',
      marginTop: 0,
      marginBottom: `${theme.spacing(4)}px`,
    },
  };
};

const WikusPageLayout = withStyles(styles)(
  ({
    classes,
    children,
    title,
    heroChildren,
    isCentered = false,
    heroWithImage = false,
    feedbackRoute = null,
    canGoToHome = false,
    userName = null,
    userRole = null,
    navigationUrls = [],
    ...props
  }: {
    [key: string]: any;
    navigationUrls?: NavigationUrl[];
  }) => {
    const { height } = useWindowDimensions();
    let mainClassName = classes.content;
    if (isCentered) {
      mainClassName += ` ${classes.contentCentered}`;
    }
    return (
      <div className={classes.container} style={{ minHeight: height }}>
        <WikusHero
          logoutRoute={Routes.logout}
          userName={userName}
          userRole={userRole}
          title={title}
          withImage={heroWithImage}
          canGoToHome={canGoToHome}
          navigationUrls={navigationUrls}
        >
          {heroChildren}
        </WikusHero>

        <main className={mainClassName}>
          <WikusSection spacingDirection="horizontal">{children}</WikusSection>
        </main>

        <footer className={classes.footer}>
          <hr className={classes.footerLine} />
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Grid direction="row" container spacing={4} wrap="nowrap">
                <Grid item>
                  <a
                    href="https://www.wikus.de/de/impressum"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: 'none' }}
                  >
                    <WikusTypography variant="caption">
                      <FormattedMessage id="footer.imprint" />
                    </WikusTypography>
                  </a>
                </Grid>
                <Grid item>
                  <a
                    href="https://www.wikus.de/de/datenschutz"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: 'none' }}
                  >
                    <WikusTypography variant="caption">
                      <FormattedMessage id="footer.privacy" />
                    </WikusTypography>
                  </a>
                </Grid>
                <Grid item>
                  <a
                    href="https://www.wikus.de/fileadmin/content/Company/Docs/WIKUS-AGB.pdf"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: 'none' }}
                  >
                    <WikusTypography variant="caption">
                      <FormattedMessage id="footer.agb" />
                    </WikusTypography>
                  </a>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <WikusTypography variant="caption">
                &copy; WIKUS {new Date().getFullYear()}
              </WikusTypography>
            </Grid>
          </Grid>
        </footer>
      </div>
    );
  }
);
WikusPageLayout.displayName = 'WikusPageLayout';

export default WikusPageLayout;
