import { ButtonProps, CircularProgress, Grid, GridProps, GridSize } from '@material-ui/core';
import { ReactNode, useState } from 'react';
import { filter } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const styles = (theme: any) => ({
  outlined: {
    borderRadius: 0,
    borderWidth: '3px',
    '&$outlinedPrimary': {
      borderWidth: '3px',
    },
    '&$outlinedSecondary': {
      borderWidth: '3px',
    },
  },
  outlinedPrimary: {
    '&$outlinedPrimary': {
      borderColor: theme.palette.primary.main,
    },
  },
  outlinedSecondary: {
    '&$outlinedSecondary': {
      borderColor: theme.palette.secondary.main,
    },
  },
});

export interface WikusButtonProps extends ButtonProps {
  loading?: boolean;
  xs?: boolean | GridSize | undefined;
}

const WikusButton = withStyles(styles)(({ classes, loading, ...props }: WikusButtonProps) => {
  const [loadingInternal, isLoadingInternal] = useState(false);
  const onClick: typeof props.onClick = async (event) => {
    isLoadingInternal(true);
    await props?.onClick?.(event);
    isLoadingInternal(false);
  }

  return (
    <Button
      variant="contained"
      classes={{
        outlined: classes?.outlined,
        outlinedPrimary: classes?.outlinedPrimary,
        outlinedSecondary: classes?.outlinedSecondary,
      }}
      {...props}
      onClick={onClick}
      endIcon={(loading || loadingInternal) && <CircularProgress style={{ width: '25px', height: '25px' }} color="inherit" />}
      disabled={props.disabled || (loading || loadingInternal)} />
  );
});
// set for storybook to render html preview correctly
WikusButton.displayName = 'WikusButton';

export interface ButtonWithIconProps extends ButtonProps {
  icon: any
}

const WikusButtonWithIcon = withStyles(styles)(({ classes, icon, ...props }: ButtonWithIconProps) => {
  const ButtonIcon: any = icon;
  return (<WikusButton
    {...props}
    variant="outlined">
    {<ButtonIcon style={{ marginRight: 5, marginTop: -2 }} color={props.color} fontSize="small" />}
    {props.children}
  </WikusButton>);
});
// set for storybook to render html preview correctly
WikusButtonWithIcon.displayName = 'WikusButtonWithIcon';

export interface WikusButtonRowProps extends GridProps {
  children: ReactNode | ReactNode[]
};

const WikusButtonRow = ({ children, ...props }: WikusButtonRowProps) =>
(
  <Grid
    {...props}
    container
    direction="row"
    wrap="nowrap"
    spacing={2}
    justifyContent="flex-end"
  >
    {Array.isArray(children)
      ? filter(children).map((child, index) => (
        <Grid key={index} item>
          {child}
        </Grid>
      ))
      : (children && <Grid item>
        {children}
      </Grid>)
    }
  </Grid>
);
WikusButtonRow.displayName = 'WikusButtonRow';

export {
  WikusButton,
  WikusButtonWithIcon,
  WikusButtonRow
};
