import { HomePage } from '../pages/home';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Routes } from '.';
import { useUser } from '../hooks/user';

export function RoleRoute({ role, roles = [], children }: any) {
  const { role: userRole } = useUser();

  if (userRole && (role === userRole || roles.includes(userRole))) {
    return (
      <Switch>
        {children}
        <Route component={HomePage} />
      </Switch>
    );
  } else if (userRole === undefined) {
    return <Redirect to={Routes.login} />;
  }

  return null;
};
