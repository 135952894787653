import { ThemeOptions } from '@material-ui/core';

const TypegraphyOptions: ThemeOptions = {
  typography: {
    fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
    fontSize: 16,
    fontWeightBold: 700,
    fontWeightRegular: 400,
    fontWeightMedium: 400,
    h1: {
      fontFamily: 'Roboto Condensed, Arial, Helvetica, sans-serif',
      fontSize: '2.25rem',
      fontWeight: 700,
      lineHeight: '2.5rem',
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 700,
      lineHeight: '2rem',
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 700,
      lineHeight: '1.55rem',
    },
    caption: {
      fontSize: '1rem',
    },
  },
};

export default TypegraphyOptions;
