import {
  CustomerStatistics,
  CustomerStatisticsAction,
  CustomerStatisticsActions,
  CustomerStatisticsFulfillOrderPayload,
  CustomerStatisticsOrdersPayload,
  CustomerStatisticsStockPayload
} from '../model';
import { RequestPayload, ResponsePayload } from '../model/request';
import { filter } from 'lodash';

import createReducer from './createReducer';

const initialState: CustomerStatistics = {
  stock: {},
  orders: [],
  minDate: '',
  maxDate: '',
  isZoomedIn: false,
};

export const customerStatistics = createReducer<CustomerStatistics>(initialState, {
  [CustomerStatisticsActions.FETCH_STATISTICS_STOCK](
    state: CustomerStatistics,
    action: CustomerStatisticsAction
  ) {
    const payload = action.payload as RequestPayload;
    const isZoomedIn = payload.request.params.year && payload.request.params.month;
    return {
      ...state,
      articleIds: payload.request.params.articleIds,
      year: payload.request.params.year,
      month: payload.request.params.month,
      isZoomedIn,
    };
  },

  [CustomerStatisticsActions.FETCH_STATISTICS_STOCK_SUCCESS](
    state: CustomerStatistics,
    action: CustomerStatisticsAction
  ) {
    const payload = action.payload as ResponsePayload<CustomerStatisticsStockPayload & {
      min: string;
      max: string;
    }>;
    const { min, max, ...data } = payload.data;
    return {
      ...state,
      stock: data,
      minDate: min,
      maxDate: max,
    };
  },
  [CustomerStatisticsActions.FETCH_STATISTICS_ORDERS_SUCCESS](
    state: CustomerStatistics,
    action: CustomerStatisticsAction
  ) {
    const payload = action.payload as ResponsePayload<CustomerStatisticsOrdersPayload>;
    return {
      ...state,
      orders: payload.data,
    };
  },
  [CustomerStatisticsActions.FULFILL_STATISTICS_ORDER_SUCCESS](
    state: CustomerStatistics,
    action: CustomerStatisticsAction
  ) {
    const payload = action.payload as ResponsePayload<CustomerStatisticsFulfillOrderPayload>;
    return {
      ...state,
      orders: filter(state.orders, o => o.id !== payload.data.id),
    };
  },
});
