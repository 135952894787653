import { FormControlLabel, GridSize, Switch, SwitchProps, makeStyles } from '@material-ui/core';
import { ReactNode, useCallback, useEffect } from 'react';
import { RequiredTrue } from '../utils/validators';
import { useInputGroup } from '../../hooks/useInputGroup';

export interface WikusSwitchProps extends Omit<SwitchProps, 'name'> {
  label?: ReactNode;
  name: string;
  group?: string;
  submitted?: boolean;
  xs?: GridSize;
}

const useStyles = makeStyles((theme) => ({
  withoutLabel: {
    marginRight: theme.spacing(2) * -1,
  },
}))

const WikusSwitch = ({ label, submitted, ...props }: WikusSwitchProps) => {
  const classes = useStyles();
  const { valueChanged } = useInputGroup(props.group);

  const update = useCallback((value: unknown) => {
    valueChanged(props.name, value, props.required ? RequiredTrue : undefined);
  }, [valueChanged, props.name]);

  const onChange = useCallback((event, child) => {
    update(event.target.value !== 'true');
    props.onChange && props.onChange(event, child);
  }, [props.onChange]);

  useEffect(() => {
    update(props.value);
  }, [props.value, update]);

  useEffect(() => {
    update(props.value || '');
  }, []);

  return (
    <FormControlLabel
      label={label}
      classes={{
        root: label ? '' : classes.withoutLabel,
      }}
      control={<Switch {...props}
        onChange={onChange} />}
    />
  )
}
WikusSwitch.displayName = 'WikusSwitch';

export {
  WikusSwitch
};