import { Middleware } from 'redux';
import { each } from 'lodash';

export function createDebounce() {
  const debounces: {
    [key: string]: { timeout?: any; caller: { resolve: any; reject: any }[] };
  } = {};
  return function(api) {
    return function(next) {
      return function(action) {
        if (!action.meta?.debounce) {
          return next(action);
        }

        const { key, time } = action.meta.debounce;
        const debounceKey = key || action.type;

        if (debounces[debounceKey]) {
          clearTimeout(debounces[debounceKey].timeout);
        } else {
          debounces[debounceKey] = {
            caller: [],
          };
        }

        debounces[debounceKey].timeout = setTimeout(() => {
          const dispatched = next(action);
          each(debounces[debounceKey].caller, ({ resolve }) => {
            resolve(dispatched);
          });
        }, time);

        return new Promise((resolve, reject) => {
          debounces[debounceKey].caller.push({
            resolve,
            reject,
          });
        });
      };
    };
  } as Middleware;
}
