import { Customer, CustomersFilter } from './customer';
import { FilterItem } from './misc';
import { RequestPayload, ResponsePayload } from './request';
import { User } from './user';

export type StockArticleCategory = 'band' | 'roll' | 'custom';

export interface StockArticle {
  id: number;
  customer: Customer;
  stock: number;
  article: Article;
  stockItems?: StockItem[];
  reorderThreshold: number;
  reorderAmount: number;
  events?: StockArticleEvent[];
  email?: string;
  name?: string;
  shortDescription?: string;
  category: StockArticleCategory;
}

export interface StockItem {
  id: number;
  stockArticle: StockArticle;
  itemNumber?: string;
  stock: number;
}

export interface Article {
  id: number;
  itemNumber: string;
  name?: string;
  status?: number;
  description?: string;
  shortDescription?: string;
  itemGroup?: string;
  itemGroupName?: string;
  itemGroupWikus?: string;
  productGroup?: string;
  productGroupName?: string;
  length?: number;
  formattedLength?: string;
  toothPerInch?: string;
  size?: string;
  containerSize?: number;
  discount: number;
  price: number;
  weldPrice: number;
  escalationThreshold: number;
  businessArea: string;
  uppercaseFirstCapital: string;
  imageUrl?: string;
  isFamily?: boolean;
  category: StockArticleCategory;
  stockArticles: StockArticle[];
}

export interface StockArticleEvent {
  id: number;
  eventName: string;
  total?: number | null;
  amount?: number | null;
  field?: number | null;
  value?: number | null;
  hidden: boolean;
  createdAt: string;
  article?: StockArticle;
  user?: User;
}

export interface StockArticleFilter {
  articles: FilterItem[];
}

export interface CreateStockArticle
  extends Pick<StockArticle, 'id' | 'reorderAmount' | 'reorderThreshold' | 'stockItems'> { }

export interface UpsertStockArticle
  extends Pick<StockArticle, 'id' | 'reorderAmount' | 'reorderThreshold'> {
  children: Pick<StockItem, 'itemNumber' | 'stock'>[];
}

export interface OrderStockArticle extends Pick<StockArticle, 'id'> {
  amount: number;
}

export enum StockArticleActions {
  FETCH_CUSTOMER_ARTICLES = 'FETCH_CUSTOMER_ARTICLES',
  FETCH_CUSTOMER_ARTICLES_SUCCESS = 'FETCH_CUSTOMER_ARTICLES_SUCCESS',
  FETCH_CUSTOMER_ARTICLES_FAIL = 'FETCH_CUSTOMER_ARTICLES_FAIL',
  FETCH_CUSTOMER_ARTICLE_EVENTS = 'FETCH_CUSTOMER_ARTICLE_EVENTS',
  FETCH_CUSTOMER_ARTICLE_EVENTS_SUCCESS = 'FETCH_CUSTOMER_ARTICLE_EVENTS_SUCCESS',
  FETCH_CUSTOMER_ARTICLE_ITEMS = 'FETCH_CUSTOMER_ARTICLE_ITEMS',
  FETCH_CUSTOMER_ARTICLE_ITEMS_SUCCESS = 'FETCH_CUSTOMER_ARTICLE_ITEMS_SUCCESS',
  FETCH_CUSTOMER_ARTICLE_ITEMS_FAIL = 'FETCH_CUSTOMER_ARTICLE_ITEMS_FAIL',

  CREATE_CUSTOMER_ARTICLE = 'CREATE_CUSTOMER_ARTICLE',
  CREATE_CUSTOMER_ARTICLE_SUCCESS = 'CREATE_CUSTOMER_ARTICLE_SUCCESS',
  UPDATE_CUSTOMER_ARTICLE = 'UPDATE_CUSTOMER_ARTICLE',
  UPDATE_CUSTOMER_ARTICLE_SUCCESS = 'UPDATE_CUSTOMER_ARTICLE_SUCCESS',
  ORDER_CUSTOMER_ARTICLE = 'ORDER_CUSTOMER_ARTICLE',
  ORDER_CUSTOMER_ARTICLE_SUCCESS = 'ORDER_CUSTOMER_ARTICLE_SUCCESS',

  DELETE_CUSTOMER_ARTICLE = 'DELETE_CUSTOMER_ARTICLE',
  DELETE_CUSTOMER_ARTICLE_SUCCESS = 'DELETE_CUSTOMER_ARTICLE_SUCCESS',

  FETCH_FILTER = 'CUSTOMER_ARTICLE:FETCH_FILTER',
  FETCH_FILTER_SUCCESS = 'CUSTOMER_ARTICLE:FETCH_FILTER_SUCCESS'
}

interface StockArticleActionType<T, P> {
  type: T;
  payload: P;
  asyncDispatch?: any;
}

export type FetchSuccessStockArticlePayload = StockArticle[];
export type FetchSuccessStockArticleItemsPayload = StockItem[];
export type DeleteSuccessStockArticlePayload = { id: number };

export type StockArticleAction =
  | StockArticleActionType<
    typeof StockArticleActions.FETCH_CUSTOMER_ARTICLES,
    RequestPayload
  >
  | StockArticleActionType<
    typeof StockArticleActions.FETCH_CUSTOMER_ARTICLES,
    ResponsePayload<FetchSuccessStockArticlePayload>
  >
  | StockArticleActionType<
    typeof StockArticleActions.FETCH_CUSTOMER_ARTICLES_FAIL,
    null
  >
  | StockArticleActionType<
    typeof StockArticleActions.FETCH_CUSTOMER_ARTICLE_ITEMS,
    RequestPayload
  >
  | StockArticleActionType<
    typeof StockArticleActions.FETCH_CUSTOMER_ARTICLE_EVENTS,
    RequestPayload
  >
  | StockArticleActionType<
    typeof StockArticleActions.FETCH_CUSTOMER_ARTICLE_EVENTS_SUCCESS,
    RequestPayload
  >
  | StockArticleActionType<
    typeof StockArticleActions.FETCH_CUSTOMER_ARTICLE_ITEMS_SUCCESS,
    ResponsePayload<FetchSuccessStockArticleItemsPayload>
  >
  | StockArticleActionType<
    typeof StockArticleActions.FETCH_CUSTOMER_ARTICLE_ITEMS_FAIL,
    null
  >
  | StockArticleActionType<
    typeof StockArticleActions.CREATE_CUSTOMER_ARTICLE,
    RequestPayload
  >
  | StockArticleActionType<
    typeof StockArticleActions.CREATE_CUSTOMER_ARTICLE_SUCCESS,
    ResponsePayload<StockArticle>
  >
  | StockArticleActionType<
    typeof StockArticleActions.UPDATE_CUSTOMER_ARTICLE,
    RequestPayload
  >
  | StockArticleActionType<
    typeof StockArticleActions.UPDATE_CUSTOMER_ARTICLE_SUCCESS,
    ResponsePayload<StockArticle>
  >
  | StockArticleActionType<
    typeof StockArticleActions.ORDER_CUSTOMER_ARTICLE,
    RequestPayload
  >
  | StockArticleActionType<
    typeof StockArticleActions.ORDER_CUSTOMER_ARTICLE_SUCCESS,
    ResponsePayload<StockArticle>
  >
  | StockArticleActionType<
    typeof StockArticleActions.DELETE_CUSTOMER_ARTICLE,
    RequestPayload
  >
  | StockArticleActionType<
    typeof StockArticleActions.DELETE_CUSTOMER_ARTICLE_SUCCESS,
    ResponsePayload<DeleteSuccessStockArticlePayload>
  >
  | StockArticleActionType<
    typeof StockArticleActions.FETCH_FILTER,
    RequestPayload
  >
  | StockArticleActionType<
    typeof StockArticleActions.FETCH_FILTER_SUCCESS,
    ResponsePayload<CustomersFilter>
  >;
