import {
  Customer,
  CustomerAction,
  CustomerActions,
  CustomerQueryParams
} from './../model';

export function fetchInitial(params?: CustomerQueryParams): CustomerAction {
  return {
    type: CustomerActions.FETCH_CUSTOMERS,
    payload: {
      request: {
        url: '/customers',
        method: 'GET',
        params,
      },
    },
  };
}

export function fetchMore(params?: CustomerQueryParams): CustomerAction {
  return {
    type: CustomerActions.FETCH_MORE_CUSTOMERS,
    payload: {
      request: {
        url: '/customers',
        method: 'GET',
        params,
      },
    },
  };
}

export function fetchMoreActive(params?: CustomerQueryParams): CustomerAction {
  return {
    type: CustomerActions.FETCH_MORE_CUSTOMERS,
    payload: {
      request: {
        url: '/customers/used',
        method: 'GET',
        params,
      },
    },
  };
}

export function fetchDetails(customerNumber: string) {
  return {
    type: CustomerActions.FETCH_DETAILS,
    payload: {
      request: {
        url: `/customers/${customerNumber}`,
        method: 'GET',
      },
    },
  };
}

export function selectCustomer(payload: Customer) {
  return {
    type: CustomerActions.SELECT_CUSTOMER,
    payload,
  };
}

export function fetchFilter() {
  return {
    type: CustomerActions.FETCH_CUSTOMERS_FILTER,
    payload: {
      request: {
        url: '/customers',
        method: 'GET',
      },
    },
  };
}
