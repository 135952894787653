import { useActions } from './../../../actions';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import * as CustomerUserActions from './../../../actions/customer-user';
import { CreateCustomerUserForm } from './createUser';
import { CustomerRouteParams } from '../../../pages/customer/models';
import { CustomerUser } from 'src/model';
import { EditCustomerUserForm } from './editUser';
import { FormattedMessage, useIntl } from 'react-intl';
import { RootState } from './../../../reducers';
import {
  CustomerUserActions as UserActionsModel
} from './../../../model/customer-user';
import { WikusAvatar } from '@wikus-ui/components/core/avatar';
import { WikusButton } from '@wikus-ui/components/core/button';
import { WikusConfirmation } from '@wikus-ui/components/core/confirmation';
import { WikusHr } from '@wikus-ui/components/core/hr';
import { WikusItem, WikusSection } from '@wikus-ui/components/core/spacing';
import { WikusModelCard } from '@wikus-ui/components/complex/model-card';
import { WikusPanel } from '@wikus-ui/components/core/card';
import { getInitials } from '@wikus-ui/utils';
import { useParams } from 'react-router-dom';
import { useUser } from '../../../hooks/user';

export const CustomerUsers = () => {
  const currentUser = useUser();
  const intl = useIntl();
  const { id: customerId } = useParams<CustomerRouteParams>();
  const customerUserActions: typeof CustomerUserActions =
    useActions(CustomerUserActions);
  const customerUsers = useSelector((state: RootState) => state.customerUsers);
  const [deleteId, setDeleteId] = useState<number>();

  const [userBeingEdited, setUserBeingEdited] = useState<CustomerUser | null>(
    null
  );

  const sortedCustomerUsers = customerUsers?.sort((a, b) =>
    a.id > b.id ? -1 : 1
  );

  useEffect(() => {
    if (customerId) {
      customerUserActions.fetchCustomerUsers(customerId);
    }
  }, []);

  const _onDelete = async () => {
    if (!deleteId) {
      return;
    }

    const { type } = await customerUserActions.deleteCustomerUser(deleteId);
    setDeleteId(undefined);
    return type === UserActionsModel.DELETE_CUSTOMER_USER_SUCCESS;
  }

  return (
    <>
      <CreateCustomerUserForm />
      <WikusSection spacingDirection="vertical">
        <WikusPanel
          title={intl.formatMessage({ id: 'customers.tabs.users.all' })}
        >
          {!sortedCustomerUsers?.length
            ? (
              <WikusPanel elevation={0} style={{ textAlign: 'center' }}>
                <FormattedMessage id="nothing-found" />
              </WikusPanel>
            )
            : undefined
          }
          {sortedCustomerUsers?.map((user, index) => (
            <WikusItem spacingDirection="down" key={user.id}>
              {index > 0 ? <WikusHr color="grey" /> : null}
              <WikusModelCard
                elevation={0}
                avatar={
                  <WikusAvatar size="large">
                    {getInitials(user.name)}
                  </WikusAvatar>
                }
                buttons={
                  userBeingEdited?.id !== user.id
                    ? [
                      <WikusButton
                        color="primary"
                        variant="outlined"
                        onClick={() => setDeleteId(user.id)}
                        disabled={currentUser.id === user.id}
                      >
                        {intl.formatMessage({ id: 'delete' })}
                      </WikusButton>,
                      <WikusButton
                        color="primary"
                        onClick={() => setUserBeingEdited(user)}
                      >
                        {intl.formatMessage({ id: 'edit' })}
                      </WikusButton>
                    ]
                    : []
                }
                details={[
                  {
                    label: intl.formatMessage({
                      id: 'customers.tabs.users.state',
                    }),
                    value: intl.formatMessage({
                      id: user.isActive ? 'active' : 'inactive',
                    }),
                  },
                  {
                    label: intl.formatMessage({
                      id: 'customers.tabs.users.role',
                    }),
                    value: user.role,
                  },
                  {
                    label: 'E-Mail',
                    value: user.email,
                  }
                ]}
                title={user.name}
              >
                <EditCustomerUserForm
                  customerUser={userBeingEdited}
                  onAbort={() => setUserBeingEdited(null)}
                  showForm={
                    userBeingEdited ? userBeingEdited.id === user.id : false
                  }
                />
              </WikusModelCard>
            </WikusItem>
          ))}
        </WikusPanel>
      </WikusSection>

      <WikusConfirmation
        title={intl.formatMessage({ id: 'customers.tabs.users.confirmation.delete' })}
        open={!!deleteId}
        onAbort={() => setDeleteId(undefined)}
        onConfirm={_onDelete}
        message="customers.tabs.users.delete">
        <FormattedMessage id="customers.tabs.users.confirmation.delete.question" />
      </WikusConfirmation>
    </>
  );
};
