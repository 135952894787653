import { Box, Grid, Theme, withStyles } from '@material-ui/core';
import { ReactNode } from 'react';
import { WikusTypography } from '../core/typography';

export interface WikusDetailRowItem {
  label: string;
  value?: string | number | ReactNode;
  isFullWidth?: boolean;
}

export type WikusDetailRowItemVariant = 'equal' | 'equal-centered' | 'equal-start' | undefined;

export interface WikusDetailRowProps {
  items?: WikusDetailRowItem[];
  variant?: WikusDetailRowItemVariant;
}

const WikusDetailRow = ({ items, variant }: WikusDetailRowProps) => {
  let itemXs: any = 'auto';
  let itemStyle: any = { width: 'auto' };
  let subItemXs: any = null;
  switch (variant) {
    case 'equal-centered':
      itemXs = 6;
      subItemXs = 6;
      break;
    case 'equal':
      itemXs = 6;
      itemStyle = null;
      break;
    case 'equal-start':
      break;
  }

  const isItemXsFullWidth = (index: number, isFullWidth?: boolean) => {
    return (variant === 'equal' || variant === 'equal-centered' || variant === 'equal-start') && ((index === (items?.length || 0) - 1 && index % 2 === 0) || isFullWidth);
  }

  const isSubItemXsFullWidth = (index: number, isFullWidth?: boolean) => {
    return (variant === 'equal-centered' || variant === 'equal') && ((index === (items?.length || 0) - 1 && index % 2 === 0) || isFullWidth);
  }

  return (
    <Grid container>
      {items?.map(({ label, value, isFullWidth }, index) => (
        <Grid style={itemStyle} container item key={index} xs={isItemXsFullWidth(index, isFullWidth) ? 12 : itemXs} wrap="nowrap">
          <Grid item style={{ textAlign: 'right' }} xs={isSubItemXsFullWidth(index, isFullWidth) ? 3 : subItemXs}>
            <WikusTypography variant="caption" isBold>{label}</WikusTypography>
          </Grid>
          <Grid item xs={isSubItemXsFullWidth(index, isFullWidth) ? 9 : subItemXs}>
            <Box ml={1} mr={3}>
              <WikusTypography variant="caption">{value || '?'}</WikusTypography>
            </Box>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};
WikusDetailRow.displayName = 'WikusDetailRow';

export interface WikusDetailBlockProps extends WikusDetailRowProps {
  title: string;
  classes: any;
  children?: ReactNode;
}

const styles = (theme: Theme) => ({
  titleLine: {
    flex: 1,
  },
  titleText: {
    padding: '0 7.5px',
  },
});

const WikusDetailBlock = withStyles(styles)(({ classes, title, ...props }: WikusDetailBlockProps) => (
  <Box width="100%">
    <Box pb={2}>
      <Grid container alignItems="center">
        <hr className={classes.titleLine}></hr>
        <WikusTypography classes={{
          root: classes?.titleText,
        }} isBold={true}>{title}</WikusTypography>
        <hr className={classes.titleLine}></hr>
      </Grid>
    </Box>
    {props.items && (
      <WikusDetailRow {...props} />
    )}
    {props.children}
  </Box>
));
WikusDetailBlock.displayName = 'WikusDetailBlock';

export {
  WikusDetailRow,
  WikusDetailBlock
}