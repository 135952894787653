import { ResponsePayload } from '../model/request';
import {
  User,
  UserAction,
  UserActions,
  UserLoginPayload
} from '../model/user';
import createReducer from './createReducer';

export const refreshToken = createReducer<string | null>(null, {
  [UserActions.LOGIN_SUCCESS](state: string, action: UserAction) {
    return (action.payload as ResponsePayload<UserLoginPayload>).data
      .refreshToken;
  },
  [UserActions.LOGOUT](state: string, action: UserAction) {
    return null;
  },
});

export const accessToken = createReducer<string | null>(null, {
  [UserActions.LOGIN_SUCCESS](state: string, action: UserAction) {
    return (action.payload as ResponsePayload<UserLoginPayload>).data
      .accessToken;
  },
  [UserActions.LOGIN_FAIL](state: string, action: UserAction) {
    return null;
  },
  [UserActions.REFRESH_TOKEN_SUCCESS](state: string, action: UserAction) {
    return (action.payload as ResponsePayload<UserLoginPayload>).data
      .accessToken;
  },
  [UserActions.LOGOUT](state: string, action: UserAction) {
    return null;
  },
});

export const user = createReducer<User | null>(null, {
  [UserActions.LOGIN_SUCCESS](state: User, action: UserAction) {
    return (action.payload as ResponsePayload<UserLoginPayload>).data
      .user as User;
  },
  [UserActions.LOGOUT](state: User, action: UserAction) {
    return null;
  },
});
