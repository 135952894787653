import { ArticleCard } from '../storage/articleCard';
import { Autocomplete } from '@material-ui/lab';
import { Chip, TextField, makeStyles } from '@material-ui/core';
import { StockArticle } from '../../../model';
import { filter } from 'lodash';
import { useIntl } from 'react-intl';

const getOptionLabel = (option: any) => option.article.itemNumber;

const renderOption = (article: any) => (
  <ArticleCard article={article} />
);

const renderTags = (value: any, getTagProps: any) =>
  value.map((option: any, index: number) => (
    <Chip style={{ height: '28px' }} variant="outlined" label={option.article.itemNumber} {...getTagProps({ index })} />
  ));

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    alignItems: 'stretch',
  },
  option: {
    padding: 0,
  },
}))

export const ArticleInput = ({ options, ...props }: { [key: string]: any, options: any[] }) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      style={{ width: '100%' }}
      classes={{
        option: classes.option,
        inputRoot: classes.inputRoot,
      }}
      options={options}
      autoHighlight
      filterOptions={(options: StockArticle[], { inputValue }) => {
        if (!inputValue || !options?.length) {
          return options;
        }

        return filter(options, (o: StockArticle) => {
          return o.article?.itemNumber?.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())
            || o.article?.itemGroupName?.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())
            || o.article?.shortDescription?.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase());
        });
      }}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      renderInput={(params) => (
        <TextField
          {...params}
          label={intl.formatMessage({ id: 'statistics.graph.articleIdsFilter' })}
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
      renderTags={renderTags}
      {...props}
    />
  );
};
