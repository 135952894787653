import { Backdrop, Box, IconButton, Theme } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles';
import { DrawerClose } from './icons';
import { DrawerProps } from '@material-ui/core';
import { Fragment, ReactNode, useCallback } from 'react';
import { WikusButtonRow } from './button';
import { WikusHero } from './hero';
import { WikusSection } from './spacing';
import { withStyles } from '@material-ui/core/styles';
import Drawer, { DrawerClassKey } from '@material-ui/core/Drawer';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const styles: any = (theme: Theme) => {
  return {
    content: {
      display: 'flex',
      flexDirection: 'column',
    },
    contentContainer: {
      flex: 1,
      overflow: 'auto',
    },
    top: {
      position: 'fixed',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      top: 0,
      right: 0,
      width: '100%',
      zIndex: theme.zIndex.drawer + 1,
    },
  };
};

export interface WikusModalClasses extends Partial<ClassNameMap<DrawerClassKey>> {
  top?: string;
  content?: string;
  contentContainer?: string;
};

export interface WikusModalProps extends DrawerProps {
  title: string;
  heroChildren?: ReactNode;
  buttons?: ReactNode[];
  classes?: WikusModalClasses;
  onClose?: {
    bivarianceHack(event: {}, reason: 'backdropClick' | 'escapeKeyDown' | 'userSelection'): void;
  }['bivarianceHack'];
}

export const WikusModalScrollContainerId = 'wikusModal';

const WikusModal = withStyles(styles)(({ classes, children, heroChildren, title, buttons, ...props }: WikusModalProps) => {
  const { height } = useWindowDimensions();
  const WikusBackdrop = useCallback(() => (<Backdrop open={props.open || false} onClick={e => props.onClose && props.onClose(e, 'backdropClick')}>
    <Box pr={2} className={classes?.top} style={{ height: height * 0.1 }}>
      <IconButton onClick={e => props.onClose && props.onClose(e, 'backdropClick')}>
        <DrawerClose />
      </IconButton>
    </Box>
  </Backdrop>), [props.open]);
  return (<Fragment>
    <Drawer
      {...props}
      anchor="bottom"
      transitionDuration={300}
      SlideProps={{
        direction: 'up',
      }}
      BackdropComponent={WikusBackdrop}>
      <div className={classes?.content} style={{ height: height * 0.9 }}>
        <div id={WikusModalScrollContainerId} className={classes?.contentContainer}>
          <WikusHero title={title} isPlain={true}>
            {heroChildren}
          </WikusHero>
          <Box style={{ position: 'relative' }} marginX={5}>
            {children}
          </Box>
        </div>
        {!!buttons?.length && (
          <WikusSection spacingDirection="both" hasBorder>
            <WikusButtonRow>
              {buttons.map((button, index) => (
                <Box key={index}>{button}</Box>
              ))}
            </WikusButtonRow>
          </WikusSection>
        )}
      </div>
    </Drawer>
  </Fragment>);
});
// set for storybook to render html preview correctly
WikusModal.displayName = 'WikusModal';

export {
  WikusModal
};