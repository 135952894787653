import {
  FetchSuccessInfoPayload,
  InfoAction,
  InfoActions,
  TInfo
} from '../model/info';
import { ResponsePayload } from '../model/request';

import createReducer from './createReducer';

export const info = createReducer<TInfo>(
  {
    languages: {},
    countries: {},
  },
  {
    [InfoActions.FETCH_INFO_SUCCESS](state: TInfo, action: InfoAction) {
      return (action.payload as ResponsePayload<FetchSuccessInfoPayload>).data;
    },
    [InfoActions.FETCH_INFO](state: TInfo, action: InfoAction) {
      return null;
    },
  }
);
