import { Alert, AlertProps } from '@material-ui/lab';
import { ReactNode } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import { WikusItem } from './spacing';
import { WikusTypography } from './typography';

export interface WikusAlertProps extends Omit<AlertProps, 'title'> {
  title: ReactNode;
  description?: ReactNode;
}

const useAlertStyles = makeStyles((theme: Theme) => ({
  icon: {
    marginTop: 2,
  },
}));

const WikusAlert = ({ title, description, children, ...props }: WikusAlertProps) => {
  const { icon } = useAlertStyles();
  return (
    <Alert classes={{ icon }} style={{ textAlign: 'left' }} {...props}>
      <WikusTypography>
        {title}
      </WikusTypography>
      {description}
      {children && <WikusItem spacingDirection="up">
        {children}
      </WikusItem>}
    </Alert>
  )
};
WikusAlert.displayName = 'WikusAlert';

export {
  WikusAlert
}