import { useIntl } from 'react-intl';

import { RootState } from './../../../reducers';

import * as CustomerUserActions from '../../../actions/customer-user';
import { CustomerRouteParams } from '../../../pages/customer/models';
import { EditCustomerForm } from '../users/editCustomer';
import { WikusItem, WikusSection } from '@wikus-ui/components/core/spacing';
import { WikusPanel } from '@wikus-ui/components/core/card';
import { useActions } from '../../../actions';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const CustomerSettings = () => {
  const intl = useIntl();
  const { id: customerId } = useParams<CustomerRouteParams>();
  const customerUserActions: typeof CustomerUserActions = useActions(CustomerUserActions);
  const customerUsers = useSelector((state: RootState) => state.customerUsers);

  useEffect(() => {
    if (customerId) {
      customerUserActions.fetchCustomerUsers(customerId);
    }
  }, []);

  return (
    <>
      <WikusSection spacingDirection="up">
        <WikusPanel
          title={intl.formatMessage({ id: 'customers.tabs.settings' })}
        >
          <WikusItem spacingDirection="down">
            <EditCustomerForm
              customerUser={customerUsers[0]}
              onEdit={() => customerUserActions.fetchCustomerUsers(customerId)}
            />
          </WikusItem>
        </WikusPanel>
      </WikusSection>
    </>
  );
};
