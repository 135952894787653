import { Redirect } from 'react-router-dom';
import { RootState } from '../../reducers';
import { Routes } from '../../routes';
import { User } from '../../model';
import { useSelector } from 'react-redux';

const getCustomerIdRoute = (user: User): string => {
  return Routes.customer.replace(':id', user?.customer?.id);
};
export const HomePage = () => {
  const user = useSelector((state: RootState) => state?.user);

  switch (user?.role) {
    case 'A':
      return <Redirect to={Routes.dashboard} />;
    case 'CA':
      return <Redirect to={getCustomerIdRoute(user)} />;
    case 'U':
      return <Redirect to={getCustomerIdRoute(user)} />;
    default:
      return <Redirect to={Routes.logout} />;
  }
};
