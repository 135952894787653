import { AppSettings } from '../../settings';
import isEmail from 'validator/lib/isEmail';

const Required = (value: unknown) => {
  if ((!value && value !== 0) || (typeof value === 'string' && value === '')) {
    return 'errors.required';
  }
};

const RequiredAutocomplete = (value: unknown) => {
  if ((!value && value !== 0) || (typeof value === 'string' && value === '') || (Array.isArray(value) && !value.length)) {
    return 'errors.required-autocomplete';
  }
};

const RequiredStrict = (value: unknown) => {
  if (!value || value === '0') {
    return 'errors.required';
  }
};

const RequiredPositiveNumber = (value: unknown) => {
  if ((!value && value !== 0) || (typeof value === 'string' && value === '')) {
    return 'errors.required';
  }

  if (parseFloat(value as string) < 0) {
    return 'errors.required-positive';
  }
};

const RequiredTrue = (value: unknown) => {
  if (!value || value !== true) {
    return 'errors.required';
  }
};

const Email = (value: unknown) => {
  if (typeof value !== 'string' || !isEmail(value)) {
    return 'errors.email';
  }
};

const RequiredEmail = (value: unknown) => {
  return Required(value) || Email(value) || undefined;
}

const ItemNumber = (value: unknown) => {
  if (typeof value === 'string' && !value.match(/^\d{5}(-\d{4})?$/g)?.length) {
    return 'errors.item-number';
  }
};

const Password = (blacklist: string[]) => (value: unknown) => {
  if (!value) {
    return 'errors.required';
  }

  if (AppSettings.isLocal) {
    return;
  }

  if (blacklist.some(b => !b)) {
    return 'errors.password-blacklist';
  }

  const pwRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*?[~!@#$%^&*_\-+='|\\\(\)\{\}\[\]:;"'<>\,\.\?\/]).{10,}$/;
  const matchesRegex = pwRegex.test(value as string);

  const consecutiveRegex = /(.)\1\1/;
  const matchesConsecutive = consecutiveRegex.test(value as string);

  const blacklistMatches = blacklist.map(word => {
    const regexWord = word.split(/(?!$)/).join('?');
    const regex = new RegExp(`${regexWord}?`, 'g');
    return (value as string).match(regex);
  });
  const matchesBlacklist = blacklistMatches.some(b => b && b.some(m => m.length > 2));

  if (!matchesRegex || matchesConsecutive || matchesBlacklist) {
    return 'errors.password';
  }
}

export { Required, RequiredPositiveNumber, RequiredAutocomplete, RequiredStrict, RequiredTrue, Email, RequiredEmail, ItemNumber, Password };
