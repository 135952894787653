import { ArticleInput } from './article-input';
import { Box, Grid } from '@material-ui/core';
import { CustomerRouteParams } from 'src/pages/customer/models';
import { CustomerStatisticsGraph } from './graph';
import { CustomerStatisticsOrders } from './orders';
import { FormattedMessage, useIntl } from 'react-intl';
import { RootState } from 'src/reducers';
import { WikusButton } from '@wikus-ui/components/core/button';
import { WikusPanel } from '@wikus-ui/components/core/card';
import { filter } from 'lodash';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const CustomerStatistics = () => {
  const intl = useIntl();
  const { id: customerId } = useParams<CustomerRouteParams>();
  const [articleIds, setArticleIds] = useState<number[]>([]);
  const [months, setMonths] = useState(6);
  const articles = useSelector((state: RootState) => state.customerArticles);

  const onChange = (_: any, newValue: any) => {
    setArticleIds(newValue.map((a: any) => a.id));
  };

  const getFilteredArticles = useCallback(() => {
    return filter(
      articles,
      a => !articleIds?.includes(a.id)
    );
  }, [articleIds, articles])

  return (
    <Grid container spacing={3}>
      <Grid item xs>
        <WikusPanel
          title={intl.formatMessage({ id: 'customers.filter' })}
        >
          <ArticleInput options={getFilteredArticles()} onChange={onChange} />
          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item>
                <WikusButton
                  color="primary"
                  variant={months === 60 ? 'contained' : 'outlined'}
                  onClick={() => setMonths(60)}>
                  <FormattedMessage id="months.60" />
                </WikusButton>
              </Grid>
              <Grid item>
                <WikusButton
                  color="primary"
                  variant={months === 24 ? 'contained' : 'outlined'}
                  onClick={() => setMonths(24)}>
                  <FormattedMessage id="months.24" />
                </WikusButton>
              </Grid>
              <Grid item>
                <WikusButton
                  color="primary"
                  variant={months === 12 ? 'contained' : 'outlined'}
                  onClick={() => setMonths(12)}>
                  <FormattedMessage id="months.12" />
                </WikusButton>
              </Grid>
              <Grid item>
                <WikusButton
                  color="primary"
                  variant={months === 6 ? 'contained' : 'outlined'}
                  onClick={() => setMonths(6)}>
                  <FormattedMessage id="months.6" />
                </WikusButton>
              </Grid>
              <Grid item>
                <WikusButton
                  color="primary"
                  variant={months === 1 ? 'contained' : 'outlined'}
                  onClick={() => setMonths(1)}>
                  <FormattedMessage id="months.1" />
                </WikusButton>
              </Grid>
            </Grid>
          </Box>
        </WikusPanel>
      </Grid>
      <Grid item container spacing={3} direction='row'>
        <Grid item xs={12} md={4}>
          <WikusPanel
            title={intl.formatMessage({ id: 'customers.orders' })}
          >
            <CustomerStatisticsOrders
              customerId={customerId}
              articleIds={articleIds}
            />
          </WikusPanel>
        </Grid>
        <Grid item xs={12} md={8}>
          <WikusPanel title={intl.formatMessage({ id: 'statistics.title' })}>
            <CustomerStatisticsGraph
              customerId={customerId}
              articleIds={articleIds}
              months={months}
            />
          </WikusPanel>
        </Grid>
      </Grid>
    </Grid>
  );
};
