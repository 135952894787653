import { CustomerPage } from './pages/customer';
import { DashboardPage } from './pages/dashboard';
import { LoginPage } from './pages/login';
import { LogoutPage } from './pages/logout';
import { PrivateRoute } from './routes/private';
import { RoleRoute } from './routes/role';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { Routes } from './routes';
import { Snackbar } from '@wikus-ui/components/Snackbar';
import { withRoot } from './withRoot';

function App() {
  return (
    <Router>
      <div>
        <Snackbar />
        <RoleRoute role="A">
          <PrivateRoute component={DashboardPage} path={Routes.dashboard} exact />
          <PrivateRoute component={CustomerPage} path={Routes.customer} />
        </RoleRoute>
        <RoleRoute roles={['U', 'CA']}>
          <PrivateRoute component={CustomerPage} path={Routes.customer} />
        </RoleRoute>
        <Route component={LogoutPage} path="/logout" />
        <Route component={LoginPage} path="/login" />
      </div>
    </Router>
  );
}

export default withRoot(App);
