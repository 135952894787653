import { ThemeOptions } from '@material-ui/core';

const MiscOptions: ThemeOptions = {
  spacing: [0, 6, 12, 16, 24, 32, 40, 48, 60, 72, 84, 96],
  shape: {
    borderRadius: 4,
  },
  shadows: [
    'none',
    '0 4px 16px 0 rgb(29 31 41 / 20%)',
    '0 8px 32px 0 rgb(29 31 41 / 20%)',
    ...Array(22).fill('0 8px 32px 0 rgb(29 31 41 / 20%)')
  ] as any,
};
export default MiscOptions;

//...Array(22).fill('0 8px 32px 0 rgb(29 31 41 / 20%')
// 0 8px 32px 0 rgb(29 31 41 / 20%)