import { Redirect, Route } from 'react-router-dom';
import { RootState } from '../reducers';
import { Routes } from '.';
import { useSelector } from 'react-redux';

export const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const isAuthenticated = useSelector(
    (state: RootState) => !!state.accessToken
  );

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: Routes.login,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};
