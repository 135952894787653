import { UserAction, UserActions } from '../model';

export function login(email: string, password: string): UserAction {
  return {
    type: UserActions.LOGIN,
    payload: {
      request: {
        url: '/auth/login',
        method: 'POST',
        data: {
          email,
          password,
        },
      },
    },
  };
}

export const RefreshUrl = '/auth/refresh';

export function refresh(email: string, refreshToken: string): UserAction {
  return {
    type: UserActions.REFRESH_TOKEN,
    payload: {
      request: {
        url: RefreshUrl,
        method: 'POST',
        data: {
          refreshToken,
          email,
        },
      },
    },
  };
}

export function logout(): UserAction {
  return {
    type: UserActions.LOGOUT,
    payload: null,
  };
}
