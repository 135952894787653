import {
  OrderStockArticle,
  StockArticleAction,
  StockArticleActions,
  UpsertStockArticle
} from './../model';

export function fetchStockArticles(
  customerId: string
): StockArticleAction {
  return {
    type: StockArticleActions.FETCH_CUSTOMER_ARTICLES,
    payload: {
      request: {
        url: `/stock-articles/forcustomer/${customerId}`,
        method: 'GET',
      },
    },
  };
}

export function fetchStockArticleItems(
  articleId: number
): StockArticleAction {
  return {
    type: StockArticleActions.FETCH_CUSTOMER_ARTICLE_ITEMS,
    payload: {
      request: {
        url: `/stock-articles/${articleId}/items`,
        method: 'GET',
      },
    },
  };
}

export function createStockArticle(
  article: UpsertStockArticle,
  customerId: string
): StockArticleAction {
  return {
    type: StockArticleActions.CREATE_CUSTOMER_ARTICLE,
    payload: {
      request: {
        url: `/stock-articles/forcustomer/${customerId}`,
        method: 'POST',
        data: article,
      },
    },
  };
}

export function updateStockArticle(
  article: UpsertStockArticle
): StockArticleAction {
  return {
    type: StockArticleActions.UPDATE_CUSTOMER_ARTICLE,
    payload: {
      request: {
        url: `/stock-articles/${article.id}`,
        method: 'PUT',
        data: article,
      },
    },
  };
}

export function orderStockArticle(
  article: OrderStockArticle
): StockArticleAction {
  return {
    type: StockArticleActions.ORDER_CUSTOMER_ARTICLE,
    payload: {
      request: {
        url: `/stock-articles/${article.id}/order`,
        method: 'POST',
        data: article,
      },
    },
  };
}

export function deleteStockArticle(
  id: string | number
): StockArticleAction {
  return {
    type: StockArticleActions.DELETE_CUSTOMER_ARTICLE,
    payload: {
      request: {
        url: `/stock-articles/${id}`,
        method: 'DELETE',
      },
    },
  };
}

export function fetchFilter(search?: string) {
  return {
    type: StockArticleActions.FETCH_FILTER,
    payload: {
      request: {
        url: '/articles/filter?search=' + (search || ''),
        method: 'GET',
      },
    },
  };
}
