import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useHrStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
  },
  grey: {
    borderColor: theme.palette.grey[300],
  },
  paper: {
    borderColor: theme.palette.background.paper,
  },
}));

export interface WikusHrProps {
  color: 'grey' | 'paper';
}

export const WikusHr = ({ color }: WikusHrProps) => {
  const styles = useHrStyles();

  return (
    <hr className={`${styles.root} ${styles[color]}`} />
  )
}