import { createTheme } from '@material-ui/core';
import ColorOptions from './colors';
import MiscOptions from './misc';
import OverrideOptions from './overrides';
import TypegraphyOptions from './typography';

export default createTheme({
  ...ColorOptions,
  ...TypegraphyOptions,
  ...OverrideOptions,
  ...MiscOptions,
});