import { Theme, withStyles } from '@material-ui/core/styles';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import Typography from '@material-ui/core/Typography';

const styles = (theme: Theme) => {
  return {
    root: {
      '&.MuiTypography-gutterBottom': {
        marginBottom: `${theme.spacing(3)}px`,
      },
    },
    h1: {
      '&.MuiTypography-gutterBottom': {
        marginBottom: `${theme.spacing(3)}px`,
      },
    },
    h2: {
      '&.MuiTypography-gutterBottom': {
        marginBottom: `${theme.spacing(3)}px`,
      },
    },
    h3: {
      '&.MuiTypography-gutterBottom': {
        marginBottom: `${theme.spacing(1)}px !important`,
      },
    },
    condensed: {
      fontFamily: 'Roboto Condensed, Arial, Helvetica, sans-serif',
    },
    hero: {
      fontFamily: 'Roboto Condensed, Arial, Helvetica, sans-serif',
      fontSize: '3rem',
      fontWeight: 700,
      lineHeight: '3.5rem',
    },
    caption: {
      color: theme?.palette?.grey?.[500],
    },
    isBold: {
      fontWeight: (theme.typography as TypographyOptions).fontWeightBold,
    },
    isClickable: {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  };
};

const WikusTypography = withStyles<'root' | 'caption'>(styles)(({ classes, isBold, ...props }: any) => {
  let className = '';
  if (isBold) {
    className += classes.isBold;
  }

  if (props.onClick) {
    className += ` ${classes.isClickable}`;
  }

  return (<Typography
    {...props}
    className={className}
    classes={{
      root: classes.root,
      caption: props.color ? '' : classes.caption,
      h1: classes.h1,
      h2: classes.h2,
      h3: classes.h3,
    }} />
  );
});
// set for storybook to render html preview correctly
WikusTypography.displayName = 'WikusTypography';

const WikusCondensedTypography = withStyles<'root' | 'caption'>(styles)(({ classes, ...props }: any) => (<Typography
  {...props}
  classes={{
    root: `${classes.root} ${classes.condensed}`,
    h1: classes.h1,
    h2: classes.h2,
    h3: classes.h3,
  }} />));
// set for storybook to render html preview correctly
WikusCondensedTypography.displayName = 'WikusCondensedTypography';

const WikusHeroTypography = withStyles<'root' | 'caption'>(styles)(({ classes, ...props }: any) => (<Typography
  {...props}
  classes={{
    root: `${classes.root} ${classes.hero}`,
  }} />));
// set for storybook to render html preview correctly
WikusHeroTypography.displayName = 'WikusHeroTypography';

export {
  WikusTypography,
  WikusCondensedTypography,
  WikusHeroTypography
};