import { Customer, CustomerAction, CustomerActions, FetchSuccessCustomerPayload } from '../model/customer';
import { ResponsePayload } from '../model/request';
import { findIndex, take, takeRight, uniqBy } from 'lodash';
import createReducer from './createReducer';

export const customers = createReducer<Customer[]>([], {
  [CustomerActions.FETCH_CUSTOMERS_SUCCESS](
    state: Customer[],
    action: CustomerAction
  ) {
    return (action.payload as ResponsePayload<FetchSuccessCustomerPayload>).data.items;
  },
  [CustomerActions.FETCH_CUSTOMERS](
    state: Customer[],
    action: CustomerAction
  ) {
    return null;
  },
  [CustomerActions.FETCH_MORE_CUSTOMERS_SUCCESS](
    state: Customer[],
    action: CustomerAction
  ) {
    return uniqBy([
      ...(state || []),
      ...(action.payload as ResponsePayload<FetchSuccessCustomerPayload>).data.items
    ], c => c.id);
  },
  [CustomerActions.FETCH_DETAILS_SUCCESS](
    state: Customer[],
    action: CustomerAction
  ) {
    const customer = (action.payload as ResponsePayload<Customer>).data;
    const index = findIndex(state, c => c.id === customer.id);

    return [
      ...take(state, index),
      customer,
      ...takeRight(state, state.length - (index + 1))
    ];
  },
});

export const customersCount = createReducer<number>(0, {
  [CustomerActions.FETCH_CUSTOMERS_SUCCESS](state: number, action: CustomerAction) {
    return (action.payload as ResponsePayload<FetchSuccessCustomerPayload>).data.count;
  },
});
