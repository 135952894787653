import { ArticleEventCard } from './articleEventCard';
import { Grid } from '@material-ui/core';
import { StockArticle } from '../../../model';
import { TWikusModelCardProps, WikusModelCard } from '@wikus-ui/components/complex/model-card';
import { WikusAvatar } from '@wikus-ui/components/core/avatar';
import { WikusModal } from '@wikus-ui/components/core/modal';
import { WikusPanel } from '@wikus-ui/components/core/card';
import { getInitials } from '@wikus-ui/utils';
import { useIntl } from 'react-intl';
import { useUser } from '../../../hooks/user';
import React from 'react';

export type ArticleCardProps = Omit<TWikusModelCardProps, 'avatar' | 'details' | 'title'> & {
  article: StockArticle;
  children?: React.ReactNode;
  isExpanded?: boolean;
  onClose?: () => void;
}

export const ArticleCard = ({
  article,
  isExpanded,
  onClose,
  children,
  ...props
}: ArticleCardProps) => {
  const intl = useIntl();
  const user = useUser();

  return (
    <>
      <WikusModelCard
        fullWidth
        elevation={0}
        avatar={
          <WikusAvatar src={article.article.imageUrl} size="large">
            {getInitials(article.article.itemGroupName)}
          </WikusAvatar>
        }
        details={[
          {
            label: intl.formatMessage({
              id: 'customers.tabs.storage.group',
            }),
            value: article.article.itemGroupName,
          },
          {
            label: intl.formatMessage({
              id: 'customers.tabs.storage.description',
            }),
            value: article.article.formattedLength,
          },
          {
            label: intl.formatMessage({
              id: 'customers.tabs.storage.stock',
            }),
            value: `${article.stock?.toString()} ${user.getUnit(article.category === 'roll')}`,
          }
        ]}
        title={article.article.itemNumber}
        {...props}
      >
      </WikusModelCard>
      <WikusModal
        title={article?.article.itemNumber}
        onClose={onClose}
        open={isExpanded}>
        <Grid container spacing={2}>
          <Grid item container xs={false} md={2}></Grid>
          <Grid item container spacing={2} md={4} alignItems='stretch' direction='column'>
            <Grid item>
              <WikusPanel
                title={intl.formatMessage({ id: 'article.settings' })}
              >
                {React.Children.map(children, c => (<Grid item>
                  {c}
                </Grid>))}
              </WikusPanel>
            </Grid>
          </Grid>
          <Grid item container spacing={2} md={4}>
            <Grid item xs>
              <WikusPanel
                title={intl.formatMessage({ id: 'article.events' })}
              >
                {article?.events?.map(event => (
                  <Grid item container justifyContent='flex-start'>
                    <ArticleEventCard
                      event={event} />
                  </Grid>
                ))}
              </WikusPanel>
            </Grid>
          </Grid>
        </Grid>
      </WikusModal>
      {/* {(isExpanded && !children) && (
        <Grid container justifyContent='center'>
          {article.events?.map(event => (
            <ArticleEventCard
              event={event} />
          ))}
        </Grid>
      )} */}
    </>
  );
};
