import { CustomerUser, CustomerUserAction, CustomerUserActions, DeleteSuccessCustomerUserPayload, FetchSuccessCustomerUserPayload } from '../model/customer-user';
import { ResponsePayload } from '../model/request';

import createReducer from './createReducer';

export const customerUsers = createReducer<CustomerUser[]>([], {
  [CustomerUserActions.FETCH_CUSTOMER_USERS_SUCCESS](
    state: CustomerUser[],
    action: CustomerUserAction
  ) {
    return (action.payload as ResponsePayload<FetchSuccessCustomerUserPayload>).data;
  },
  [CustomerUserActions.CREATE_CUSTOMER_USER_SUCCESS](
    state: CustomerUser[],
    action: CustomerUserAction
  ) {
    return [
      (action.payload as ResponsePayload<CustomerUser>).data,
      ...(state || [])
    ];
  },
  [CustomerUserActions.UPDATE_CUSTOMER_USER_SUCCESS](
    state: CustomerUser[],
    action: CustomerUserAction
  ) {
    const { data } = action.payload as ResponsePayload<CustomerUser>;
    const userIndex = state.findIndex(s => s.id === data.id);

    if (userIndex < 0) {
      return state;
    }

    return [
      ...state.slice(0, userIndex),
      {
        ...state[userIndex],
        ...data,
      },
      ...state.slice(userIndex + 1)
    ]
  },
  [CustomerUserActions.DELETE_CUSTOMER_USER_SUCCESS](
    state: CustomerUser[],
    action: CustomerUserAction
  ) {
    const userId = (action.payload as ResponsePayload<DeleteSuccessCustomerUserPayload>).data.id;
    const userIndex = state.findIndex(s => s.id === +userId);

    return [
      ...state.slice(0, userIndex),
      ...state.slice(userIndex + 1)
    ]
  },
});
