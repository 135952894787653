import { IWikusAutocompleteItem } from '../components/core/autocomlete';
import { InputValidator } from '../components/core/input';
import { RequiredAutocomplete } from '../components/utils/validators';
import { debounce, filter, isString, map } from 'lodash';
import { useActions } from '../actions';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

export interface UseAutocompleteActions {
  fetchFilter: (search?: string) => any;
}

export interface AutocompleteResult {
  [key: string]: IWikusAutocompleteItem[];
}

export interface UseAutocompleteOptions {
  searchOnType: boolean;
}

export const useAutocomplete = <T extends UseAutocompleteActions, F>(actions: T, translationKey: string, options: UseAutocompleteOptions = { searchOnType: false }) => {
  const filterActions: T = useActions(actions);
  const [filterOptions, setFilterOptions] = useState<any>();
  const [isLoading, setLoading] = useState(false);
  const intl = useIntl();

  async function fetchFilter(search?: string) {
    const { payload } = await filterActions.fetchFilter(search);
    const data = payload?.data;
    if (data) {
      setFilterOptions(data);
    }
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);

    fetchFilter();
  }, []);

  const onSearch = async (event: any) => {
    const searchValue = event.target.value;
    setLoading(true);
    await fetchFilter(searchValue);
    setLoading(false);
  }

  const createAutocompleteProps = useCallback((name: keyof F, validator: InputValidator = RequiredAutocomplete, optionFilter = () => true) => {
    return {
      label: intl.formatMessage({ id: `${translationKey}.${String(name)}.label` }),
      name,
      validator,
      onKeyUp: options.searchOnType ? debounce(onSearch, 1000) : undefined,
      options: map(filter(filterOptions?.[name] || [], optionFilter), filterItem => {
        if (isString(filterItem)) {
          return {
            id: filterItem,
            name: intl.formatMessage({
              id: `${translationKey}.${String(name)}.item.${filterItem}`,
            }),
          };
        }

        return filterItem;
      }),
    }
  }, [filterOptions]);

  return {
    createAutocompleteProps,
    filterOptions: filterOptions as F,
    isLoading,
  }
}