import {
  DeleteSuccessStockArticlePayload,
  FetchSuccessStockArticleItemsPayload,
  FetchSuccessStockArticlePayload,
  StockArticle,
  StockArticleAction,
  StockArticleActions
} from '../model/customer-article';
import { ResponsePayload } from '../model/request';

import createReducer from './createReducer';

export const customerArticles = createReducer<StockArticle[]>([], {
  [StockArticleActions.FETCH_CUSTOMER_ARTICLES_SUCCESS](
    state: StockArticle[],
    action: StockArticleAction
  ) {
    return (
      action.payload as ResponsePayload<FetchSuccessStockArticlePayload>
    ).data;
  },
  [StockArticleActions.FETCH_CUSTOMER_ARTICLE_ITEMS_SUCCESS](
    state: StockArticle[],
    action: StockArticleAction
  ) {
    const payload = action.payload as ResponsePayload<FetchSuccessStockArticleItemsPayload>;
    const articleId = payload.config.url.replace(/.*\/(\d+)\/items/, '$1');
    const articleIndex = state.findIndex(s => s.id === +articleId);

    if (articleIndex < 0) {
      return state;
    }

    return [
      ...state.slice(0, articleIndex),
      {
        ...state[articleIndex],
        stockItems: payload.data,
      },
      ...state.slice(articleIndex + 1)
    ]
  },
  [StockArticleActions.UPDATE_CUSTOMER_ARTICLE_SUCCESS](
    state: StockArticle[],
    action: StockArticleAction
  ) {
    const { data } = action.payload as ResponsePayload<StockArticle>;
    const articleIndex = state.findIndex(s => s.id === data.id);

    if (articleIndex < 0) {
      return state;
    }

    return [
      ...state.slice(0, articleIndex),
      {
        ...state[articleIndex],
        ...data,
      },
      ...state.slice(articleIndex + 1)
    ]
  },
  [StockArticleActions.CREATE_CUSTOMER_ARTICLE_SUCCESS](
    state: StockArticle[],
    action: StockArticleAction
  ) {
    return [
      (action.payload as ResponsePayload<StockArticle>).data,
      ...(state || [])
    ];
  },
  [StockArticleActions.DELETE_CUSTOMER_ARTICLE_SUCCESS](
    state: StockArticle[],
    action: StockArticleAction
  ) {
    const articleId = (action.payload as ResponsePayload<DeleteSuccessStockArticlePayload>).data.id;
    const articleIndex = state.findIndex(s => s.id === +articleId);

    return [
      ...state.slice(0, articleIndex),
      ...state.slice(articleIndex + 1)
    ]
  },
});
