import { Customer } from './customer';
import { RequestPayload, ResponsePayload } from './request';

export enum UserActions {
  LOGIN = 'LOGIN',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAIL = 'LOGIN_FAIL',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS',
  LOGOUT = 'LOGOUT'
}

interface UserActionType<T, P> {
  type: T;
  payload: P;
}

export interface User {
  id: number;
  email: string;
  name: string;
  firstName: string;
  lastName: string;
  role: string;
  isActive: boolean;
  imperial: boolean;
  customer: Customer;
  language: string;
}

export interface UserLoginPayload {
  refreshToken: string;
  accessToken: string;
  user: any;
}

export type UserAction =
  | UserActionType<typeof UserActions.LOGIN, RequestPayload>
  | UserActionType<
    typeof UserActions.LOGIN_SUCCESS,
    ResponsePayload<UserLoginPayload>
  >
  | UserActionType<typeof UserActions.REFRESH_TOKEN, RequestPayload>
  | UserActionType<
    typeof UserActions.REFRESH_TOKEN_SUCCESS,
    ResponsePayload<UserLoginPayload>
  >
  | UserActionType<typeof UserActions.LOGOUT, null>
  | UserActionType<typeof UserActions.LOGIN_FAIL, null>
