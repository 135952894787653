import * as ReactDOM from 'react-dom';
import {
  BarElement,
  CategoryScale,
  Chart,
  Colors,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeSeriesScale,
  Title,
  Tooltip
} from 'chart.js';
import { ReduxRoot } from './ReduxRoot';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(
  Colors,
  CategoryScale,
  LinearScale,
  TimeSeriesScale,
  LineElement,
  BarElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);
Chart.defaults.set('plugins.datalabels', {
  color: '#000',
  anchor: 'end',
  align: 'end',
  labels: {
    title: {
      font: {
        weight: 'bold',
        size: 14,
      },
    },
  },
});

const rootEl = document.getElementById('root');
ReactDOM.render(<ReduxRoot />, rootEl);

// comment in for PWA with service worker in production mode
// registerServiceWorker();
