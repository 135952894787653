import { useIntl } from 'react-intl';

import { WikusAvatar } from '@wikus-ui/components/core/avatar';
import { WikusButton } from '@wikus-ui/components/core/button';
import { WikusInfiniteScroll } from '@wikus-ui/components/complex/infinite-scroll';
import { WikusItem, WikusSection } from '@wikus-ui/components/core/spacing';
import { WikusModelCard } from '@wikus-ui/components/complex/model-card';
import { WikusPanel } from '@wikus-ui/components/core/card';
import WikusPageLayout from '@wikus-ui/components/PageLayout';

import * as CustomerActions from './../../actions/customer';

import { Customer } from 'src/model';
import { RootState } from 'src/reducers';
import { getInitials } from '@wikus-ui/utils';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { usePaginate } from '@wikus-ui/hooks/usePaginate';
import { useSelector } from 'react-redux';

import { CustomerFilter } from '../../components/customer/users/filter';
import { WikusFilterValue } from '@wikus-ui/components/complex/filter';
import { WikusHr } from '@wikus-ui/components/core/hr';
import { trim } from 'lodash';

export const DashboardPage = () => {
  const intl = useIntl();

  const user = useSelector((state: RootState) => state.user);

  const customerPerPage = 50;
  const customers = useSelector((state: RootState) => state.customers);
  const customersCount = useSelector(
    (state: RootState) => state.customersCount
  );

  const [filters, setFilters] = useState({});
  const [paginateActions, setPaginateActions] = useState<any>(CustomerActions);

  const history = useHistory();

  const { fetchMore, isLoading } = usePaginate<typeof CustomerActions>({
    itemsPerPage: customerPerPage,
    actions: paginateActions,
    filters,
  });

  const handleEditClick = (customer: Customer) => {
    history.push(`/customer/${trim(customer.id)}`);
  };

  const onCustomerFilter = (filters: WikusFilterValue) => {
    setFilters(filters);
  };

  useEffect(() => {
    setPaginateActions(CustomerActions);
  }, []);

  return (
    <WikusPageLayout
      canGoToHome={true}
      userName={`${user.firstName} ${user.lastName}`}
      userRole={intl.formatMessage({ id: `roles.${user.role}` })}
      title={intl.formatMessage({ id: 'customers' })}
      heroWithImage={true}
    >
      <CustomerFilter onChange={onCustomerFilter} />

      <WikusSection spacingDirection="up">
        <WikusPanel
          title={intl.formatMessage({ id: 'customers.all' })}
        >
          <WikusInfiniteScroll
            skeletonHeight={100}
            skeletonCount={customerPerPage}
            dataLength={customers?.length}
            next={fetchMore}
            hasMore={customersCount > customers?.length}
            isLoading={isLoading}
          >
            {customers?.map((customer, index) => (
              <WikusItem spacingDirection="down" key={customer.id}>
                {index > 0 ? <WikusHr color="grey" /> : null}
                <WikusModelCard
                  elevation={0}
                  avatar={
                    <WikusAvatar size="large">
                      {getInitials(customer.customerName)}
                    </WikusAvatar>
                  }
                  buttons={[
                    <WikusButton
                      color="primary"
                      onClick={() => handleEditClick(customer)}
                    >
                      {intl.formatMessage({ id: 'edit' })}
                    </WikusButton>
                  ]}
                  details={[{
                    label: intl.formatMessage({
                      id: 'customers.details.customerNumber',
                    }),
                    value: customer.customerNumber,
                  }, {
                    label: intl.formatMessage({
                      id: 'customers.details.addressNumber',
                    }),
                    value: customer.addressNumber,
                  }]}
                  title={customer.customerName}
                />
              </WikusItem>
            ))}
          </WikusInfiniteScroll>
        </WikusPanel>
      </WikusSection>
    </WikusPageLayout>
  );
};
