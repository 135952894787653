
import {
  WikusAutocomplete,
  WikusAutocompleteProps
} from '@wikus-ui/components/core/autocomlete';
import { useAutocomplete } from '@wikus-ui/hooks/useAutocomplete';

import * as StockArticleActions from './../../../actions/customer-article';
import { StockArticleFilter } from './../../../model/customer-article';

export const ArticlesAutocomplete = (
  props: Omit<WikusAutocompleteProps, 'options'>
) => {
  const { isLoading, createAutocompleteProps } = useAutocomplete<
    typeof StockArticleActions,
    StockArticleFilter
  >(StockArticleActions, 'customer-article', {
    searchOnType: true,
  });

  return (
    <WikusAutocomplete
      {...props}
      {...createAutocompleteProps('articles')}
      loading={isLoading}
      autoSelect={true}
    />
  );
};
