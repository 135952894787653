import { Redirect } from 'react-router-dom';
import { Routes } from '../../routes';
import { logout } from '../../actions/user';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

export const LogoutPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
  }, []);

  return <Redirect to={Routes.login} />;
}
