import { InfoAction, InfoActions } from './../model';

export function fetchInfo(): InfoAction {
  return {
    type: InfoActions.FETCH_INFO,
    payload: {
      request: {
        url: '/info',
        method: 'GET',
      },
    },
  };
}
