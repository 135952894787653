import { Box } from '@material-ui/core';
import { Chip, ChipProps } from '@material-ui/core';
import { WikusTypography } from './typography';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme: any) => {
  return {
    root: {
      borderRadius: theme.shape.borderRadius,
    },
    avatarWithCaption: {
      width: 'auto !important',
      backgroundColor: 'transparent !important',
      marginLeft: `${theme.spacing(2)}px !important`,
      marginRight: '0 !important',
      fontSize: 'inherit !important',
      height: 'auto !important',
      display: 'inline-flex',
      alignItems: 'center',
    },
    label: {
      display: 'inline-flex',
    },
    labelWithCaption: {
      paddingLeft: '0 !important',
    },
  }
};

interface WikusTagProps extends ChipProps {
  caption?: string;
}

const WikusTag = withStyles(styles)(({ classes, caption, ...props }: WikusTagProps) => {
  if (caption) {
    props.avatar = (
      <Box>
        <WikusTypography component="span" isBold={true} style={{ fontSize: 'inherit', lineHeight: 'inherit' }}>{caption}</WikusTypography>
        <WikusTypography component="span" style={{ margin: '0 5px' }}>|</WikusTypography>
      </Box>
    );
  }
  return (<Chip
    classes={{
      root: classes?.root,
      avatar: caption && (classes as any)?.avatarWithCaption,
      label: (caption && (classes as any)?.labelWithCaption) || (classes as any).label,
    }}
    {...props} />);
});
// set for storybook to render html preview correctly
WikusTag.displayName = 'WikusTag';

export {
  WikusTag
};