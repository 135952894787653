import { ThemeOptions } from '@material-ui/core';

const OverrideOptions: ThemeOptions = {
  overrides: {
    MuiOutlinedInput: {
      root: {
        height: '48px',
      },
      notchedOutline: {
        borderRadius: 0,
        borderWidth: '3px !important',
        borderColor: '#E4E5EB !important',
        top: '-4px',
      },
      input: {
        padding: '12.5px 14px',
      },
      multiline: {
        height: 'auto !important',
      },
      inputMultiline: {
        height: 'auto !important',
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(14px, 14px) scale(1)',
      },
      formControl: {
        top: '2px',
      },
    },
    MuiFormLabel: {
      root: {
        '&.Mui-focused': {
          color: '#1D1F29',
        },
      },
    },
    MuiButton: {
      root: {
        height: '48px',
        fontWeight: 700,
        textTransform: 'none',
        whiteSpace: 'nowrap',
      },
      contained: {
        boxShadow: 'none',
        borderRadius: 0,
        '&:hover': {
          borderRadius: 0,
          boxShadow: 'none',
        },
      },
      outlined: {
        borderRadius: 0,
        borderWidth: '3px',
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: '#fff',
        },
      },
    },
  },
}

export default OverrideOptions;