import { withStyles } from '@material-ui/core/styles';
import logoPng from '../../assets/img/logo.png';

const styles = (theme: any) => ({
  logo: {
    width: '100px',
    height: '42px',
  },
});

const Logo = withStyles(styles)(({ classes, ...props }: any) => (
  <img {...props} className={classes.logo} src={logoPng} alt="Wikus Logo" />
));
Logo.displayName = 'Logo';

export {
  Logo
};