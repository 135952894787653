import { RequestPayload, ResponsePayload } from './request';
import { StockArticleEvent } from './customer-article';

export interface CustomerStatistics {
  stock: CustomerStatisticsStockPayload;
  orders: StockArticleEvent[];
  articleIds?: string[];
  year?: number | null;
  month?: number | null;
  minDate: string;
  maxDate: string;
  isZoomedIn: boolean;
}

export interface CustomerStatisticsStock {
  date: string;
  total: number;
  withdrawals: number;
  confirmed: number;
}

export interface CustomerStatisticsStockPayload {
  [articleId: string | 'all']: CustomerStatisticsStock[];
}

export type CustomerStatisticsOrdersPayload = StockArticleEvent[];

export type CustomerStatisticsFulfillOrderPayload = StockArticleEvent;

export enum CustomerStatisticsActions {
  FETCH_STATISTICS_STOCK = 'FETCH_STATISTICS_STOCK',
  FETCH_STATISTICS_STOCK_SUCCESS = 'FETCH_STATISTICS_STOCK_SUCCESS',
  FETCH_STATISTICS_ORDERS = 'FETCH_STATISTICS_ORDERS',
  FETCH_STATISTICS_ORDERS_SUCCESS = 'FETCH_STATISTICS_ORDERS_SUCCESS',
  FULFILL_STATISTICS_ORDER = 'FULFILL_STATISTICS_ORDER',
  FULFILL_STATISTICS_ORDER_SUCCESS = 'FULFILL_STATISTICS_ORDER_SUCCESS'
}

export interface CustomerStatisticsActionType<T, P> {
  type: T;
  payload: P;
  asyncDispatch?: any;
}

export type CustomerStatisticsAction =
  | CustomerStatisticsActionType<
    typeof CustomerStatisticsActions.FETCH_STATISTICS_STOCK,
    RequestPayload
  >
  | CustomerStatisticsActionType<
    typeof CustomerStatisticsActions.FETCH_STATISTICS_STOCK_SUCCESS,
    ResponsePayload<CustomerStatisticsStockPayload>
  >
  | CustomerStatisticsActionType<
    typeof CustomerStatisticsActions.FETCH_STATISTICS_ORDERS,
    RequestPayload
  >
  | CustomerStatisticsActionType<
    typeof CustomerStatisticsActions.FETCH_STATISTICS_ORDERS_SUCCESS,
    ResponsePayload<CustomerStatisticsOrdersPayload>
  >
  | CustomerStatisticsActionType<
    typeof CustomerStatisticsActions.FULFILL_STATISTICS_ORDER,
    RequestPayload
  >
  | CustomerStatisticsActionType<
    typeof CustomerStatisticsActions.FULFILL_STATISTICS_ORDER_SUCCESS,
    ResponsePayload<CustomerStatisticsFulfillOrderPayload>
  >;
