import { InputAction, InputActions, InputDefaultGroup, RemovePayload, SetGroupPayload, SetSubmittedPayload, StageChangePayload } from '../model/input';
import { every, omit, set } from 'lodash';
import createReducer from './createReducer';

export const inputGroup = createReducer<any>({}, {
  [InputActions.STATE_CHANGE](state: any, action: InputAction) {
    const { name, group, value, isValid } = action.payload as StageChangePayload;
    const key = group || InputDefaultGroup;
    const oldFields = omit(state[key]?.validationState, name);

    const newState = {
      ...state,
      [key]: {
        ...(state[key] || {}),
        submitted: state[key]?.submitted || false,
        isValid: every(oldFields, (val) => val !== false) && isValid,
      },
    }
    set(newState, `${key}.value.${name}`, value);
    set(newState, `${key}.validationState.${name}`, isValid);
    return newState;
  },
  [InputActions.REMOVE_FIELD](state: any, action: InputAction) {
    const { name, group } = action.payload as RemovePayload;
    const key = group || InputDefaultGroup;

    return {
      ...state,
      [key]: {
        value: state[key]?.value && omit(state[key]?.value, name),
        validationState: state[key]?.validationState && omit(state[key]?.validationState, name),
        isValid: state[key]?.isValid,
      },
    };
  },
  [InputActions.REMOVE_GROUP](state: any, action: InputAction) {
    const key = action.payload as string;

    return state && omit(state, key);
  },
  [InputActions.SET_GROUP](state: any, action: InputAction) {
    const { group, value } = action.payload as SetGroupPayload;

    return {
      ...state,
      [group]: {
        ...(state?.[group] || {}),
        ...value,
      },
    }
  },
  [InputActions.SET_SUBMITTED](state: any, action: InputAction) {
    const { group, submitted } = action.payload as SetSubmittedPayload;

    return {
      ...state,
      [group]: {
        ...state[group],
        submitted,
      },
    }
  },
});