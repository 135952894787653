import { Box, Grid, Theme } from '@material-ui/core';
import { ReactNode } from 'react';
import { WikusButtonRow } from '../core/button';
import {
  WikusDetailRow,
  WikusDetailRowItem,
  WikusDetailRowItemVariant
} from './detail-block';
import { WikusItem } from '../core/spacing';
import { WikusPanel, WikusPanelSize } from '../core/card';
import { WikusTypography } from '../core/typography';
import { createStyles, withStyles } from '@material-ui/styles';

const styles = (theme: Theme) =>
  createStyles({
    error: {
      backgroundColor: theme.palette.error.light,
    },
    success: {
      backgroundColor: theme.palette.success.light,
    },
    clickable: {
      '&:hover': {
        backgroundColor: theme.palette.grey['300'],
      },
      cursor: 'pointer',
    },
  });

export type TWikusModelCardProps = {
  avatar: React.ReactNode;
  actionItem?: React.ReactNode;
  title: string;
  details: WikusDetailRowItem[];
  detailsVariant?: WikusDetailRowItemVariant;

  buttons?: ReactNode[];

  onClick?: (param: any) => void;

  tags?: React.ReactNode[];

  size?: WikusPanelSize;
  status?: 'success' | 'error';
  classes?: any;
  fullWidth?: boolean;
  elevation?: number;
  children?: React.ReactNode;
};

const WikusModelCard = withStyles(styles)(
  ({
    avatar,
    actionItem,
    title,
    details,
    detailsVariant,
    buttons,
    onClick,
    fullWidth,
    tags,
    size = 'small',
    classes,
    status,
    elevation,
    children,
  }: TWikusModelCardProps) => {
    return (
      <WikusPanel
        elevation={elevation}
        style={fullWidth ? { width: '100%' } : {}}
        size={size}
        {...(status && {
          classes: {
            root: classes[status],
          },
        })}
      >
        <Grid
          className={onClick ? classes.clickable : ''} container alignItems="center" justifyContent="space-between"
          onClick={onClick}>
          <Grid item>
            <Box p={3}>
              {avatar}
            </Box>
          </Grid>

          <Grid item sm>
            <WikusItem spacingDirection="horizontal">
              <Grid container>
                <Grid item>
                  <WikusItem spacingDirection="right">
                    <WikusTypography variant="h2" color="primary">
                      {title}
                    </WikusTypography>
                  </WikusItem>
                </Grid>
                {tags && (
                  <Grid item>
                    <Box pb={1}>
                      <Grid container>
                        {tags.map((tag, index) => (
                          <Grid item style={{ marginRight: 8 }} key={index}>
                            {tag}
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Grid>
                )}
              </Grid>

              <WikusDetailRow variant={detailsVariant} items={details} />
            </WikusItem>
          </Grid>
          <Grid item>{actionItem}</Grid>
        </Grid>
        {children && <WikusItem spacingDirection="up">{children}</WikusItem>}
        {!!buttons?.length && (
          <WikusItem spacingDirection="up">
            <WikusButtonRow>
              {buttons.map((button, index) => (
                <Box key={index}>{button}</Box>
              ))}
            </WikusButtonRow>
          </WikusItem>
        )}
      </WikusPanel>
    );
  }
);

WikusModelCard.displayName = 'WikusModelCard';

export { WikusModelCard };
