import * as configReducer from './config';
import { History } from 'history';
import { combineReducers } from 'redux';

import * as inputReducer from '@wikus-ui/reducers/input';
import * as snackbarReducer from '@wikus-ui/reducers/snackbarEvent';
import {
  Customer,
  CustomerStatistics,
  CustomerUser,
  SnackbarEvent,
  StockArticle,
  TInfo,
  User
} from '../model';

import * as customerArticleReducer from './customer-article';
import * as customerReducer from './customer';
import * as customerStatisticsReducer from './customer-statistics';
import * as customerUserReducer from './customer-user';
import * as infoReducer from './info';
import * as userReducer from './user';

export interface RootState {
  currentLocale: string;
  snackbarEvents: SnackbarEvent[];
  accessToken: string;
  refreshToken: string;
  user: User;
  users: User[];
  customers: Customer[];
  customersCount: number;
  customerUsers: CustomerUser[];
  customerArticles: StockArticle[];
  customerStatistics: CustomerStatistics;
  inputGroup: any;
  info: TInfo;
}

export default (history: History) =>
  combineReducers({
    ...configReducer,
    ...snackbarReducer,
    ...userReducer,
    ...inputReducer,
    ...customerReducer,
    ...customerUserReducer,
    ...customerArticleReducer,
    ...customerStatisticsReducer,
    ...infoReducer,
  });
