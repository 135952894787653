import { ChevronLeftOutlined, EmailOutlined, ExpandLessRounded, ExpandMoreRounded, InfoOutlined, ThumbUp, ThumbUpOutlined, WarningOutlined } from '@material-ui/icons';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PlayCircleOutlineOutlinedIcon from '@material-ui/icons/PlayCircleOutlineOutlined';

type TIconProps = {
  color?: string;
};

const DrawerClose = () => <CloseIcon style={{ color: '#fff' }} fontSize="large" />;

const AccordionOpen = () => <ExpandMoreRounded />
const AccordionClose = () => <ExpandLessRounded />;

const InfoIcon = () => <InfoOutlined />;

const DeleteAction = () => <HighlightOffIcon />;

const EditIcon = ({ color }: TIconProps) => (
  <BorderColorIcon htmlColor={color} />
);

const PriceSliderCheckIcon = ({ color }: TIconProps) => (
  <CheckCircleIcon htmlColor={color} />
);

const PriceSliderErrorIcon = ({ color }: TIconProps) => (
  <ErrorIcon htmlColor={color} />
);

const TeaserAction = (props: any) => (
  <PlayCircleOutlineOutlinedIcon {...props} fontSize="large" />
);

const AvatarWarningIcon = (props: any) => (
  <WarningOutlined {...props} />
)

const ProcessEmailIcon = (props: any) => (
  <EmailOutlined {...props} />
)

const HeaderChevron = () => <ChevronLeftOutlined style={{ height: 20, marginTop: -1, marginRight: -2 }} fontSize="small" />;

const UpvoteIcon = ({ isUpvoted }: any) => isUpvoted
  ? <ThumbUp color="primary" fontSize="large" />
  : <ThumbUpOutlined fontSize="large" />;

export {
  DrawerClose,
  TeaserAction,
  AccordionOpen,
  AccordionClose,
  EditIcon,
  DeleteAction,
  PriceSliderCheckIcon,
  PriceSliderErrorIcon,
  AvatarWarningIcon,
  ProcessEmailIcon,
  InfoIcon,
  HeaderChevron,
  UpvoteIcon
};
