import { CssBaseline } from '@material-ui/core';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from '@material-ui/styles';
import { useSelector } from 'react-redux';

import WikusTheme from '@wikus-ui/components/theme/wikus';

import { RootState } from './reducers';
import Locales, { DefaultLocale } from './i18n/config';

export function withRoot(Component: any) {
  function WithRoot(props: object) {
    const currentUser = useSelector(
      (state: RootState) => state.user
    );
    const currentLocale = currentUser?.language || window.navigator.language;
    const localeConfig = Locales[currentLocale] || Locales[DefaultLocale];
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <ThemeProvider theme={WikusTheme}>
        <IntlProvider
          messages={localeConfig.messages}
          locale={localeConfig.locale}
          defaultLocale={DefaultLocale}
        >
          {/* Reboot kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <Component {...props} />
        </IntlProvider>
      </ThemeProvider>
    );
  }

  return WithRoot;
}
