import {
  WikusFilter,
  WikusFilterOption,
  WikusFilterOptionChangeHandler,
  WikusFilterOptionType
} from '@wikus-ui/components/complex/filter';
import { useFilter } from '@wikus-ui/hooks/useFilter';
import { useIntl } from 'react-intl';

import * as CustomerActions from './../../../actions/customer';

export interface CustomerFilterProps {
  onChange: WikusFilterOptionChangeHandler;
}

export const CustomerFilter = ({ onChange }: CustomerFilterProps) => {
  const intl = useIntl();

  const nameFilter = {
    type: WikusFilterOptionType.text,
    props: {
      name: 'name',
    },
  };

  const { isLoading, filter } = useFilter<typeof CustomerActions>(
    CustomerActions,
    [nameFilter, {
      type: WikusFilterOptionType.bool,
      props: {
        name: 'existing',
      },
    }],
    'customers.filter',
    false
  );

  const filterTitle = intl.formatMessage({
    id: 'customers.filter.search',
  });

  return (
    <WikusFilter
      title={filterTitle}
      description=""
      isLoading={isLoading}
      options={filter}
      order={['name']}
      onChange={onChange}
      initialToggledFilter={nameFilter as WikusFilterOption}
    />
  );
};
