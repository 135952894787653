import * as InputActions from '../actions/input';
import * as SnackbarEventAction from '../actions/snackbarEvent';
import { FormEvent, MouseEvent, useCallback, useState } from 'react';
import { InputDefaultGroup } from '../model/input';
import { InputValidator } from '../components/core/input';
import { Required } from '../components/utils/validators';
import { RootState } from '../reducers';
import { get, isUndefined, omit } from 'lodash';
import { useActions } from '../actions';
import { useDebounce } from 'use-debounce';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

export type OnSubmitHandler = (event: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>) => Promise<boolean | void>;

export type CreateInputPropsOptions = {
  labelParams: { [key: string]: string }
}

export const useInputGroup = (group: string = InputDefaultGroup, onSubmit?: OnSubmitHandler, translateGroup?: string) => {
  const intl = useIntl();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingDebounced] = useDebounce(isSubmitting, 300);
  const { isValid, validationState, value, submitted } = useSelector((rootState: RootState) => rootState.inputGroup[group] || {});
  const inputActions: typeof InputActions = useActions(InputActions);
  const snackbarActions: typeof SnackbarEventAction = useActions(
    SnackbarEventAction
  );

  const stateChange = useCallback((name: string, value: unknown, isValid: boolean) => {
    inputActions.stateChange(name, value, isValid, group);
  }, [group, inputActions]);

  const removeField = useCallback((name: string) => {
    inputActions.removeField(name, group);
  }, [group, inputActions]);

  const clear = useCallback(() => {
    inputActions.removeGroup(group);
  }, [group, inputActions]);

  const setGroup = useCallback((value: any) => {
    inputActions.setGroup(group, value);
  }, [group, inputActions]);

  const getValue = useCallback((path: string) => {
    return get(value, path);
  }, [value]);

  const valueChanged = useCallback((name: string, value: unknown, validator?: InputValidator) => {
    let message = undefined;
    if (validator) {
      message = validator(value);
    }

    stateChange(name, value, !message);
    return message;
  }, []);

  const onSubmitInternal = async (event: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    inputActions.setSubmitted(group, true);

    setIsSubmitting(true);
    const success = await onSubmit?.(event);
    setIsSubmitting(false);

    if (isUndefined(success)) {
      return;
    }

    if (success) {
      snackbarActions.addSnackbarEvent({
        message: intl.formatMessage({
          id: `success.${translateGroup || group}`,
        }),
        severity: 'success',
      });
    } else {
      snackbarActions.addSnackbarEvent({
        message: intl.formatMessage({
          id: `errors.${translateGroup || group}`,
        }),
        severity: 'error',
      });
    }
  }

  const formatNameForLabel = (name: string) => {
    return name.replace(/\[\d\]/, '');
  }

  const createInputProps = useCallback((name: string, validator = Required, options?: CreateInputPropsOptions) => {
    return {
      group,
      label: intl.formatMessage({ id: `${translateGroup || group}.${formatNameForLabel(name)}.label` }, options?.labelParams),
      name,
      validator,
      value: getValue(name),
      submitted,
    }
  }, [intl, getValue, submitted, group]);

  const createSwitchProps = useCallback((name: string) => {
    return {
      ...omit(createInputProps(name), 'validator'),
      checked: !!getValue(name),
    };
  }, [createInputProps, getValue]);

  return {
    isValid,
    validationState,
    value,
    submitted,
    stateChange,
    removeField,
    clear,
    getValue,
    setGroup,
    valueChanged,
    createInputProps,
    createSwitchProps,
    onSubmit: onSubmitInternal,
    isSubmitting: isSubmittingDebounced,
  };
}
