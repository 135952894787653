import { Email, Password, Required } from '@wikus-ui/components/utils/validators';
import { OnSubmitHandler, useInputGroup } from '@wikus-ui/hooks/useInputGroup';
import { WikusButton, WikusButtonRow } from '@wikus-ui/components/core/button';
import { WikusInputRow, WikusTextField } from '@wikus-ui/components/core/input';
import { WikusPanel } from '@wikus-ui/components/core/card';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import * as CustomerUserActions from './../../../actions/customer-user';
import {
  CreateCustomerUser,
  CustomerUserActions as CustomerUserActionsModel
} from './../../../model/customer-user';
import { useActions } from './../../../actions';

import { CustomerRouteParams } from '../../../pages/customer/models';
import { WikusSelect } from '@wikus-ui/components/core/select';
import { useParams } from 'react-router-dom';
import { useUser } from '../../../hooks/user';

type TCreateCustomerUserFormProps = {
  onCreate?: () => void;
};

export const CreateCustomerUserForm = ({
  onCreate,
}: TCreateCustomerUserFormProps) => {
  const user = useUser();
  const { id: customerId } = useParams<CustomerRouteParams>();
  const intl = useIntl();
  const CreateCustomerUserInputGroup = `create-user-${customerId}`;

  const customerUserActions: typeof CustomerUserActions =
    useActions(CustomerUserActions);

  const _onSubmit: OnSubmitHandler = async () => {
    if (!isValid) {
      return;
    }

    const { type } = await customerUserActions.createCustomerUser(
      {
        ...value,
      } as unknown as CreateCustomerUser,
      customerId
    );
    const isSuccess =
      type === CustomerUserActionsModel.CREATE_CUSTOMER_USER_SUCCESS;

    if (isSuccess) {
      clear();
      onCreate && onCreate();
    }

    return isSuccess;
  };

  const { value, isValid, clear, onSubmit, createInputProps, isSubmitting } =
    useInputGroup(CreateCustomerUserInputGroup, _onSubmit, 'create-user');
  const passwordValidator = useCallback(
    (password: unknown) => {
      return Password([value?.firstName, value?.lastName])(password);
    },
    [value?.firstName, value?.lastName]
  );

  const options = [
    {
      id: 'U',
      name: intl.formatMessage({ id: 'roles.U' }),
    },
    {
      id: 'CA',
      name: intl.formatMessage({ id: 'roles.CA' }),
    }
  ];

  // if (user.role === 'A') {
  //   options.push({
  //     id: 'A',
  //     name: intl.formatMessage({ id: 'roles.A' }),
  //   });
  // }

  const mustHaveEmail = value?.role !== 'U';

  return (
    <WikusPanel
      title={intl.formatMessage({ id: 'customers.tabs.users.create' })}
    >
      <form onSubmit={onSubmit}>
        <WikusInputRow>
          <WikusTextField {...createInputProps('firstName')} xs={6} />
          <WikusTextField {...createInputProps('lastName')} xs={6} />
        </WikusInputRow>
        <WikusInputRow>
          <WikusTextField {...createInputProps('email', mustHaveEmail ? Email : Required)} xs={6} />
          <WikusTextField
            {...createInputProps('password')}
            validator={passwordValidator}
            type="password"
            xs={6}
          />
        </WikusInputRow>
        <WikusInputRow>
          <WikusSelect
            {...createInputProps('role')}
            options={options}
          />
        </WikusInputRow>
        <WikusButtonRow>
          <WikusButton type="submit" color="primary" disabled={isSubmitting}>
            {intl.formatMessage({
              id: 'create-user.submit',
            })}
          </WikusButton>
        </WikusButtonRow>
      </form>
    </WikusPanel>
  );
};
