export enum ConfigActions {
  PURGE_STATE = 'PURGE_STATE',
  SET_CURRENT_LOCALE = 'SET_CURRENT_LOCALE'
}

interface ConfigActionType<T, P> {
  type: T;
  payload: P;
}

export type ConfigAction =
  | ConfigActionType<typeof ConfigActions.SET_CURRENT_LOCALE, string>
  | ConfigActionType<typeof ConfigActions.PURGE_STATE, undefined>;
