import * as localforage from 'localforage';
import { AppSettings } from './settings';
import { PersistConfig, persistReducer, persistStore } from 'redux-persist';
import { RefreshUrl, logout, refresh } from './actions/user';
import { applyMiddleware, createStore } from 'redux';
import { asyncDispatch } from './middlewares/async-dispatch';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
import { createDebounce } from './middlewares/debounce';
import { createLogger } from 'redux-logger';
import { multiClientMiddleware } from 'redux-axios-middleware';
import axios from 'axios';
import rootReducer from './reducers/index';
import thunk from 'redux-thunk';

import qs from 'qs';

const dev = process.env.NODE_ENV === 'development';

const persistConfig: PersistConfig<any> = {
  key: 'root',
  version: 1,
  storage: localforage,
  blacklist: [],
};

const logger = (createLogger as any)();
const history = createBrowserHistory();

const clients = {
  default: {
    client: axios.create({
      baseURL: AppSettings.baseUrl,
      responseType: 'json',
      paramsSerializer: (params) => qs.stringify(params),
    }),
  },
};
const axiosConfig = {
  interceptors: {
    request: [
      async function({ getState, dispatch }: any, config: any) {
        const { accessToken } = getState();
        config.headers = {
          Authorization: 'Bearer ' + accessToken,
          'Content-Type': 'application/json',
        };
        return config;
      }
    ],
    response: [
      {
        error: async function(
          { getState, dispatch, ...rest }: any,
          originalRequest: any
        ) {
          const { refreshToken, user } = getState();
          if (
            originalRequest?.response?.status === 401 &&
            refreshToken &&
            originalRequest?.config?.url !== RefreshUrl
          ) {
            try {
              const {
                payload: {
                  data: { accessToken },
                },
              } = await dispatch(refresh(user.email, refreshToken));
              originalRequest.config.headers['Authorization'] =
                'Bearer ' + accessToken;
              return await axios(originalRequest.config);
            } catch (err) {
              console.log(err);
              await dispatch(logout());
              window.location.reload();
            }
          } else if (
            originalRequest?.response?.status === 401 &&
            originalRequest?.config?.reduxSourceAction?.type !== 'LOGIN'
          ) {
            await dispatch(logout());
          }

          throw originalRequest;
        },
      }
    ],
  },
};
const axiosMiddleware = multiClientMiddleware(clients, axiosConfig);

// let middleware = dev ? applyMiddleware(createDebounce(), axiosMiddleware, thunk, logger) : applyMiddleware(axiosMiddleware, thunk);
let middleware = dev
  ? applyMiddleware(createDebounce(), asyncDispatch(), axiosMiddleware, thunk)
  : applyMiddleware(createDebounce(), asyncDispatch(), axiosMiddleware, thunk);

if (dev) {
  middleware = composeWithDevTools(middleware);
}

const persistedReducer = persistReducer(persistConfig, rootReducer(history));

export default (initialValue = {}) => {
  const store = createStore(persistedReducer, initialValue, middleware) as any;
  const persistor = persistStore(store);
  return { store, persistor };
};

export { history };
