import { RootState } from '../reducers';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

export function useUser() {
  const intl = useIntl();
  const currentUser = useSelector(
    (state: RootState) => state.user
  );

  return {
    ...currentUser,
    getUnit(isRoll?: boolean) {
      if (!isRoll) {
        return intl.formatMessage({ id: 'units.pieces' });
      }

      return currentUser.imperial
        ? intl.formatMessage({ id: 'units.foot' })
        : intl.formatMessage({ id: 'units.meter' });
    },
  }
}