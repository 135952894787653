import { WikusPanel } from '@wikus-ui/components/core/card';
import { useIntl } from 'react-intl';

import * as StockArticleActions from './../../../actions/customer-article';
import {
  StockArticleActions as StockArticleActionsModel,
  UpsertStockArticle
} from './../../../model/customer-article';
import { useActions } from './../../../actions';

import { ArticleForm } from './articleForm';
import { CustomerRouteParams } from '../../../pages/customer/models';
import { useParams } from 'react-router-dom';

type TCreateStockArticleFormProps = {
  onCreate?: () => void;
};

export const CreateArticleForm = ({
  onCreate,
}: TCreateStockArticleFormProps) => {
  const { id: customerId } = useParams<CustomerRouteParams>();

  const intl = useIntl();
  const customerArticleActions: typeof StockArticleActions = useActions(
    StockArticleActions
  );

  const onSubmit = async (article: UpsertStockArticle) => {
    const resp = await customerArticleActions.createStockArticle(article, customerId);
    const isSuccess =
      resp.type === StockArticleActionsModel.CREATE_CUSTOMER_ARTICLE_SUCCESS;

    if (isSuccess) {
      onCreate && onCreate();
    }

    return isSuccess;
  };

  return (
    <WikusPanel
      title={intl.formatMessage({ id: 'customer-article.create' })}
    >
      <ArticleForm onSubmit={onSubmit} />
    </WikusPanel>
  );
};
