import { RequestPayload, ResponsePayload } from './request';

export interface Languages {
  languages: {
    [key: string]: string;
  };
}

export interface Countries {
  countries: {
    [key: string]: string;
  };
}

export type TInfo = Languages & Countries;

export enum InfoActions {
  FETCH_INFO = 'FETCH_INFO',
  FETCH_INFO_SUCCESS = 'FETCH_INFO_SUCCESS'
}

interface InfoActionsType<T, P> {
  type: T;
  payload: P;
  asyncDispatch?: any;
}

export type FetchSuccessInfoPayload = TInfo;

export type InfoAction =
  | InfoActionsType<typeof InfoActions.FETCH_INFO, RequestPayload>
  | InfoActionsType<
      typeof InfoActions.FETCH_INFO_SUCCESS,
      ResponsePayload<FetchSuccessInfoPayload>
    >;
