export enum InputActions {
  STATE_CHANGE = 'STATE_CHANGE',
  REMOVE_FIELD = 'REMOVE_FIELD',
  REMOVE_GROUP = 'REMOVE_GROUP',
  SET_GROUP = 'SET_GROUP',
  SET_SUBMITTED = 'SET_SUBMITTED'
}

export const InputDefaultGroup = 'all';

export interface InputActionType<T, P> {
  type: T;
  payload: P;
}

export interface StageChangePayload {
  group?: string;
  name: string;
  value: unknown;
  isValid: boolean;
}
export interface RemovePayload {
  group?: string;
  name: string;
}
export interface SetGroupPayload {
  group: string;
  value: any;
}
export interface SetSubmittedPayload {
  group: string;
  submitted: boolean;
}

export type InputAction =
  | InputActionType<typeof InputActions.STATE_CHANGE, StageChangePayload>
  | InputActionType<typeof InputActions.REMOVE_FIELD, RemovePayload>
  | InputActionType<typeof InputActions.REMOVE_GROUP, string>
  | InputActionType<typeof InputActions.SET_GROUP, SetGroupPayload>
  | InputActionType<typeof InputActions.SET_SUBMITTED, SetSubmittedPayload>
