import { Box } from '@material-ui/core';
import { BoxProps } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme: any) => ({
  withBorder: {
    borderTop: `1px solid ${theme.palette?.grey?.[400]}`,
  },
});

export interface WikusSpacingClasses {
  root?: string;
  withBorder?: string;
}

export interface WikusSpacingProps extends BoxProps {
  classes: WikusSpacingClasses;
  spacingDirection: 'vertical' | 'horizontal' | 'both' | 'down' | 'left' | 'right' | 'up' | 'none';
  hasBorder?: boolean;
}

const CreateWikusSpacing = (y: number, x: number) => withStyles(styles)(({ classes, hasBorder, spacingDirection, ...props }: WikusSpacingProps) => {
  if (spacingDirection === 'vertical' || spacingDirection === 'both') {
    props.py = y;
  }

  if (spacingDirection === 'horizontal' || spacingDirection === 'both') {
    props.px = x;
  }

  if (spacingDirection === 'down') {
    props.pb = y;
  }

  if (spacingDirection === 'up') {
    props.pt = y;
  }

  if (spacingDirection === 'left') {
    props.pl = x;
  }

  if (spacingDirection === 'right') {
    props.pr = x;
  }

  return (
    <Box
      className={(!!hasBorder && classes.withBorder) || undefined}
      {...props}>

    </Box>
  )
});
const WikusSection = CreateWikusSpacing(4, 5);
WikusSection.displayName = 'WikusSection';

const WikusItem = CreateWikusSpacing(2, 4);
WikusItem.displayName = 'WikusItem';

export {
  WikusSection,
  WikusItem
};