import { Autocomplete, AutocompleteProps, AutocompleteRenderInputParams } from '@material-ui/lab';
import { GridSize, Popper, Theme } from '@material-ui/core';
import { InputValidator, WikusTextField } from './input';
import { countries } from '../utils/i18n';
import { filter, get, map, uniq } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useInputGroup } from '../../hooks/useInputGroup';
import { useIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';

const styles = (theme: Theme) => ({
  input: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
  },
  tag: {
    height: 28,
  },
});

export interface IWikusAutocompleteItem {
  id: string;
  name: string;
  group?: string;
  decimal?: number;
}

export interface WikusAutocompleteProps extends Omit<AutocompleteProps<IWikusAutocompleteItem, true, true, true>, 'renderInput'> {
  name: string;
  label?: string;
  group?: string;
  submitted?: boolean;
  loading?: boolean;
  validator?: InputValidator;
  helperText?: string;
  xs?: boolean | GridSize;
}

export const WikusAutocomplete = withStyles(styles)(({ classes, name, label, group, submitted, validator, helperText, xs, autoSelect, ...props }: WikusAutocompleteProps) => {
  const intl = useIntl();
  const [key, setKey] = useState(name);
  const [validationMessage, setValidationMessage] = useState<string | undefined>();
  const { value, valueChanged } = useInputGroup(group);

  // Used as a fix for mobile safari, but breaks the search functionality by clearing the search input on first type
  // const updatePosition = useCallback(() => {
  //   let i = 0;
  //   const interval = setInterval(() => {
  //     setKey(name + i);
  //     if (i === 5) {
  //       clearInterval(interval);
  //       return;
  //     }

  //     i++;
  //   }, 100);
  // }, [name]);

  const update = useCallback((value: unknown) => {
    const message = valueChanged(name, value, validator);
    const validationMessage = message
      ? intl.formatMessage({
        id: message,
      })
      : undefined;
    setValidationMessage(validationMessage);
  }, [valueChanged, name, intl, validator]);

  const onChange = useCallback((event, value, reason, details) => {
    update(value);
    props.onChange && props.onChange(event, value, reason, details);
  }, [props.onChange]);

  const uniqueGroups = filter(uniq(map(props.options, o => o.group)));
  const groupBy = uniqueGroups.length > 1
    ? (option: IWikusAutocompleteItem) => option.group as string
    : undefined;

  useEffect(() => {
    update(props.value || []);
  }, []);

  useEffect(() => {
    update(props.value);
  }, [props.value, update]);

  const currentValue = props.value || get(value, name, []);
  const hasNoValue = !currentValue || currentValue?.length === 0;
  useEffect(() => {
    if (autoSelect && props.options?.length === 1 && hasNoValue) {
      update(props.options[0]);
    }
  }, [autoSelect, props.options?.length])

  const hasError = !!(submitted && validationMessage);
  const autoHelperText =
    (hasError &&
      validationMessage) || helperText;

  return (
    <Autocomplete
      {...props}
      onChange={onChange}
      disabled={props.disabled || props.loading}
      // ref={anchorRef}
      freeSolo={true}
      clearOnBlur={false}
      classes={{
        input: classes?.input,
        tag: classes?.tag,
      }}
      PopperComponent={({ ...props }) => (
        <Popper
          {...props}
          key={key}
        />
      )}
      loadingText={intl.formatMessage({
        id: 'loading.items',
      })}
      // Set menu max height (optional)
      // ListboxProps={{ style: { maxHeight: '30vh' } }}
      // PopperComponent={(pp) => <Popper key={name} {...pp} />}
      getOptionLabel={(option) => option?.name || ''}
      groupBy={groupBy}
      // renderInput={(params) => <TextField {...params} label="Combo box" variant="outlined" />}

      renderInput={(params: AutocompleteRenderInputParams) => {
        return (<WikusTextField
          {...params}
          name={name}
          label={
            hasNoValue && props.loading
              ? intl.formatMessage({
                id: 'loading.items',
              })
              : label
          }
          group={group}
          xs={xs}
          submitted={submitted}
          helperText={autoHelperText}
          error={hasError}
          noStateChange={true} />)
      }}
    />
  );
});

export const WikusCountryAutocomplete = ({ ...props }: Omit<WikusAutocompleteProps, 'options'>) => {
  const intl = useIntl();
  const translatedCountries = countries.getNames(intl.locale);
  const options = map(translatedCountries, (name, id) => ({
    id,
    name,
  }))

  return (
    <WikusAutocomplete options={options} {...props} />
  )
}
