import { Link, LinkProps } from 'react-router-dom';

export interface WikusLinkProps extends LinkProps {
  external?: boolean;
}

export const WikusUnstyledLink = ({ external, ...props }: WikusLinkProps) => {
  if (external) {
    return (
      <a style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer" {...props}>
      </a>
    )
  }

  return (
    <Link {...props} style={{ textDecoration: 'none' }} />
  );
};