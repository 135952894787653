import { OnSubmitHandler, useInputGroup } from '@wikus-ui/hooks/useInputGroup';
import { OrderStockArticle, StockArticle } from '../../../model';
import { RequiredPositiveNumber } from '@wikus-ui/components/utils/validators';
import { WikusButton } from '@wikus-ui/components/core/button';
import { WikusInputRow, WikusTextField } from '@wikus-ui/components/core/input';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useUser } from '../../../hooks/user';

type TStockArticleOrderFormProps = {
  article: StockArticle;
  onSubmit: (order: OrderStockArticle) => Promise<boolean>;
};

export const ArticleOrderForm = ({
  article,
  onSubmit,
}: TStockArticleOrderFormProps) => {
  const intl = useIntl();
  const user = useUser();
  const OrderStockArticleInputGroup = `order-article-${article.id}`;
  const _onSubmit: OnSubmitHandler = async () => {
    if (!isValid) {
      return;
    }

    const isSuccess = await onSubmit(
      {
        id: article.id,
        amount: value.amount,
      }
    );

    if (isSuccess) {
      setGroup({
        submitted: false,
        value: {
          amount: 1,
        },
      });
    }

    return isSuccess;
  }
  const { value, isValid, onSubmit: onSubmitInternal, createInputProps, isSubmitting, setGroup } =
    useInputGroup(OrderStockArticleInputGroup, _onSubmit, 'order-article');

  useEffect(() => {
    console.log('set article', article);
    setGroup({
      submitted: false,
      value: {
        amount: article.reorderAmount || 1,
      },
    });
  }, [article])

  return (<form onSubmit={onSubmitInternal}>
    <WikusInputRow>
      <WikusTextField
        type="number"
        {...createInputProps('amount', RequiredPositiveNumber, {
          labelParams: {
            unit: user.getUnit(value?.articles?.group === 'R'),
          },
        })}
        xs
      />

      <WikusButton type="submit" color="primary" disabled={isSubmitting || isValid === undefined}>
        {intl.formatMessage({
          id: 'customer-article.order.submit',
        })}
      </WikusButton>
    </WikusInputRow>
  </form>);
}