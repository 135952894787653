import { ConfigAction, ConfigActions } from '../model';
import { DefaultLocale } from '../i18n/config';
import createReducer from './createReducer';

export const currentLocale = createReducer<string>(DefaultLocale, {
  [ConfigActions.SET_CURRENT_LOCALE](state: string, action: ConfigAction) {
    return action.payload;
  },
  [ConfigActions.PURGE_STATE](state: boolean, action: ConfigAction) {
    return DefaultLocale;
  },
});
