import { InputAction, InputActions } from '../model/input';

export function stateChange(name: string, value: unknown, isValid: boolean, group?: string): InputAction {
  return {
    type: InputActions.STATE_CHANGE,
    payload: {
      name,
      value,
      group,
      isValid,
    },
  };
}

export function removeField(name: string, group?: string): InputAction {
  return {
    type: InputActions.REMOVE_FIELD,
    payload: {
      name,
      group,
    },
  };
}

export function removeGroup(group: string): InputAction {
  return {
    type: InputActions.REMOVE_GROUP,
    payload: group,
  };
}

export function setGroup(group: string, value: any): InputAction {
  return {
    type: InputActions.SET_GROUP,
    payload: {
      group,
      value,
    },
  };
}

export function setSubmitted(group: string, submitted: boolean): InputAction {
  return {
    type: InputActions.SET_SUBMITTED,
    payload: {
      group,
      submitted,
    },
  };
}