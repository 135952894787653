import '@formatjs/intl-pluralrules/locale-data/de';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/locale-data/fr';
import '@formatjs/intl-pluralrules/locale-data/it';
import '@formatjs/intl-pluralrules/locale-data/se';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/de';
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/fr';
import '@formatjs/intl-relativetimeformat/locale-data/it';
import '@formatjs/intl-relativetimeformat/locale-data/se';
import '@formatjs/intl-relativetimeformat/polyfill';

import deMessages from './de.json';
import enMessages from './en.json';
import frMessages from './fr.json';
import itMessages from './it.json';
import seMessages from './se.json';
export const DefaultLocale = 'en';

export interface ILocaleConfig {
  messages: any;
  formats: any;
  locale: string;
}

export default {
  de: {
    locale: 'de',
    messages: deMessages,
    formats: {
      number: {
        percentWith2Decimals: { style: 'percent', maximumFractionDigits: 2 },
      },
    },
  },
  en: {
    locale: 'en',
    messages: enMessages,
    formats: {
      number: {
        percentWith2Decimals: { style: 'percent', maximumFractionDigits: 2 },
      },
    },
  },
  fr: {
    locale: 'fr',
    messages: frMessages,
    formats: {
      number: {
        percentWith2Decimals: { style: 'percent', maximumFractionDigits: 2 },
      },
    },
  },
  it: {
    locale: 'it',
    messages: itMessages,
    formats: {
      number: {
        percentWith2Decimals: { style: 'percent', maximumFractionDigits: 2 },
      },
    },
  },
  se: {
    locale: 'se',
    messages: seMessages,
    formats: {
      number: {
        percentWith2Decimals: { style: 'percent', maximumFractionDigits: 2 },
      },
    },
  },
} as { [key: string]: ILocaleConfig };
