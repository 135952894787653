// prettier-ignore
import * as React from 'react';
import * as SnackbarEventActions from '../actions/snackbarEvent';
import { Snackbar as MuiSnackbar, makeStyles } from '@material-ui/core';
import { RootState } from '../reducers';
import { SnackbarEvent } from '../model/snackbarEvent';
import { WikusAlert } from './core/alert';
import { useActions } from '../actions';
import { useSelector } from 'react-redux';

export function Snackbar() {
  const classes = useStyles();
  const snackbarEvents: SnackbarEvent[] = useSelector(
    (state: RootState) => state.snackbarEvents
  );
  const snackbarEventActions: typeof SnackbarEventActions = useActions(
    SnackbarEventActions
  );
  const [currentEvent, setCurrentEvent] = React.useState(
    snackbarEvents.length > 0 ? snackbarEvents[0] : undefined
  );

  React.useEffect(() => {
    setCurrentEvent(
      snackbarEvents.length > 0 ? snackbarEvents[0] : undefined
    );
  }, [snackbarEvents]);

  const onClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    if (currentEvent) {
      snackbarEventActions.deleteSnackbarEvent(currentEvent);
    }
  };

  if (currentEvent) {
    return (
      <MuiSnackbar
        open={!!currentEvent}
        autoHideDuration={
          currentEvent.severity === 'info' ? 2000 : 6000
        }
        onClose={onClose}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        transitionDuration={500}
        className={classes.root}
      >
        <WikusAlert
          data-test="snackbar"
          onClose={onClose}
          severity={currentEvent.severity}
          variant={
            currentEvent.severity === 'info' ? 'standard' : 'filled'
          }
          title={currentEvent.message}
        >
        </WikusAlert>
      </MuiSnackbar>
    );
  } else {
    return <></>;
  }
}

const useStyles = makeStyles({
  root: {
    zIndex: 99999999,
  },
});
