import { OnSubmitHandler, useInputGroup } from '@wikus-ui/hooks/useInputGroup';
import { WikusButton, WikusButtonRow } from '@wikus-ui/components/core/button';
import { WikusInputRow, WikusTextField } from '@wikus-ui/components/core/input';
import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
  StockArticle,
  UpsertStockArticle
} from './../../../model/customer-article';

import { ArticlesAutocomplete } from './articlesAutocomplete';
import { CustomerRouteParams } from '../../../pages/customer/models';
import { Email, RequiredPositiveNumber } from '@wikus-ui/components/utils/validators';
import { omit } from 'lodash';
import { useParams } from 'react-router-dom';
import { useUser } from '../../../hooks/user';

type TStockArticleFormProps = {
  article?: StockArticle;
  onSubmit: (article: UpsertStockArticle) => Promise<boolean>;
  onAbort?: () => void;
};

export const ArticleForm = ({
  article,
  onSubmit,
  onAbort,
}: TStockArticleFormProps) => {
  const { id: customerId } = useParams<CustomerRouteParams>();
  const intl = useIntl();
  const user = useUser();
  const isCreateArticle = !article;
  const UpsertStockArticleInputGroup = isCreateArticle ? `create-customer-article-${customerId}` : `customer-article-${article.id}`;

  const _onSubmit: OnSubmitHandler = async () => {
    if (!isValid) {
      return;
    }

    const isSuccess = await onSubmit(
      {
        ...omit(value, ['articles', 'stock']),
        itemNumber: value.articles.id,
        reorderThreshold: parseFloat(value.reorderThreshold),
        reorderAmount: parseFloat(value.reorderAmount),
        customArticle: isCustomArticle
          ? {
            email: value.email,
            itemGroupName: value.name,
            shortDescription: value.shortDescription,
          }
          : undefined,
        children: value.children.map((c: any) => ({
          ...c,
          stock: parseFloat(c.stock),
        })),
      } as unknown as UpsertStockArticle
    );

    if (isSuccess && isCreateArticle) {
      setGroup({
        submitted: false,
        value: {
          children: [{
            itemNumber: '',
            stock: undefined,
          }],
        },
      });
    }

    return isSuccess;
  };

  const { value, isValid, onSubmit: onSubmitInternal, createInputProps, isSubmitting, setGroup } =
    useInputGroup(UpsertStockArticleInputGroup, _onSubmit, 'customer-article');

  useEffect(() => {
    if (isCreateArticle) {
      setGroup({
        submitted: false,
        value: {
          children: [{
            itemNumber: '',
            stock: undefined,
          }],
        },
      });

      return;
    }

    setGroup({
      submitted: false,
      value: {
        ...article,
        articles: {
          category: article.article.category,
        },
        children: article.stockItems?.map((i) => ({
          itemNumber: i.itemNumber,
          stock: i.stock,
        })),
      },
    });
  }, []);

  const addChild = () => {
    setGroup({
      value: {
        ...value,
        children: [...value.children, {
          itemNumber: '',
          stock: undefined,
        }],
      },
    });
  }

  const removeChild = (index: number) => {
    setGroup({
      value: {
        ...value,
        children: [
          ...value.children.slice(0, index),
          ...value.children.slice(index + 1)
        ],
      },
    });
  }

  const isRoll = useMemo(() => value?.articles?.category === 'roll', [value?.articles?.category]);
  const isCustomArticle = useMemo(() => value?.articles?.category === 'custom', [value?.articles?.category]);

  return (
    <form onSubmit={onSubmitInternal}>
      {isCreateArticle
        ? (
          <WikusInputRow>
            <ArticlesAutocomplete {...createInputProps('articles')} />
          </WikusInputRow>
        )
        : undefined
      }
      <WikusInputRow>
        <WikusTextField
          type="number"
          {...createInputProps('reorderThreshold', RequiredPositiveNumber, {
            labelParams: {
              unit: user.getUnit(isRoll),
            },
          })}
          xs={6}
        />
        <WikusTextField
          type="number"
          {...createInputProps('reorderAmount', RequiredPositiveNumber, {
            labelParams: {
              unit: user.getUnit(isRoll),
            },
          })}
          xs={6}
        />
      </WikusInputRow>
      {
        value?.children?.map((_: any, index: number) => (
          <WikusInputRow key={index}>
            {isRoll ? <WikusTextField
              {...createInputProps(`children[${index}][itemNumber]`)}
              xs
            /> : undefined}
            <WikusTextField
              type="number"
              {...createInputProps(`children[${index}][stock]`, RequiredPositiveNumber, {
                labelParams: {
                  unit: user.getUnit(isRoll),
                },
              })}
              xs
            />
            {
              isRoll
                ? (<WikusButton onClick={() => removeChild(index)} color="primary" variant="outlined">
                  {'-'}
                </WikusButton>)
                : undefined
            }
          </WikusInputRow>
        ))
      }
      {
        isRoll
          ? (<WikusInputRow><WikusButtonRow><WikusButton onClick={() => addChild()} color="primary" variant="outlined">
            {'+'}
          </WikusButton></WikusButtonRow></WikusInputRow>)
          : undefined
      }
      {
        isCustomArticle && (
          <WikusInputRow>
            <WikusTextField
              {...createInputProps('email', Email)}
              xs={6}
            />
          </WikusInputRow>
        )
      }
      {
        isCustomArticle && (
          <WikusInputRow>
            <WikusTextField
              {...createInputProps('name')}
              xs={6}
            />
          </WikusInputRow>
        )
      }
      {
        isCustomArticle && (
          <WikusInputRow>
            <WikusTextField
              {...createInputProps('shortDescription')}
              xs={6}
            />
          </WikusInputRow>
        )
      }
      <WikusButtonRow>
        <WikusButton type="submit" color="primary" disabled={isSubmitting || isValid === undefined}>
          {intl.formatMessage({
            id: 'customer-article.create.submit',
          })}
        </WikusButton>
        {
          !isCreateArticle && !!onAbort
            ? (
              <WikusButton
                onClick={onAbort}
                color="primary"
                variant="outlined"
                disabled={isSubmitting}
              >
                {intl.formatMessage({
                  id: 'customer-article.abort',
                })}
              </WikusButton>
            )
            : undefined
        }
      </WikusButtonRow>
    </form>
  );
};
